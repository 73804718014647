export class LoteRecurso {
    nomeLote: string;
    dataAbertura: Date;
    loteId: number;
    qtdRegistrosFeitos: number;
    qtdRegistrosLotes: number;
    dataHoraUsuarioVinculado: Date;
    usuarioVinculado: number;
    nomeUsuarioVinculado: string;

    constructor(response: any){
        this.nomeLote = response.nomeLote;
        this.dataAbertura = response.dataAbertura;
        this.loteId = response.loteID;
        this.qtdRegistrosFeitos = response.qtdRegistrosFeitos;
        this.qtdRegistrosLotes = response.qtdRegistrosLote;
        this.dataHoraUsuarioVinculado = response.dataVinculoUsuario;
        this.usuarioVinculado = response.usuarioVinculadoId;
        this.nomeUsuarioVinculado = response.nomeUsuarioVinculado;
    }
}