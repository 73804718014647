import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { BaseService } from "../../../core/services/base.service";
import { HttpService } from "../../../core/services/http.service";


@Injectable({
    providedIn: 'root',
})
  
export class LoteService extends BaseService {

    constructor(oAuthService: OAuthService,
        private httpService: HttpService){
        super(oAuthService)
    }


    public async getLotes(): Promise<any> {        
        const response = this.httpService.get(`${this.baseUrl()}/api/lotes/aberto`,
            this.getHeaderJson()
        );
        return this.extractData(response)
    }

    
    public async vincularLoteAoUsuario(loteId: number): Promise<any> {
        const response = this.httpService.post(`${this.baseUrl()}/api/lotes`,
            loteId,
            this.getHeaderJson()
        );
        return this.extractData(response)
    }
    
    public async desvincularLoteAoUsuario(loteId: number): Promise<any> {
        const response = this.httpService.post(`${this.baseUrl()}/api/lotes/desvincular`,
            loteId,
            this.getHeaderJson()
        );
        return this.extractData(response)
    }


    
    public async fecharLote(loteId: number): Promise<void> {
        const params =  new HttpParams().set('loteId', loteId);
        const response = this.httpService.put(`${this.baseUrl()}/api/lotes/fechar`,
        {headers: this.getHeaders(), params: params}
        );
        return this.extractData(response)
    }
    
}