<div class="container-home">
    <section class="container-blue">
        <div class="container d-flex">
            <h2>Processos Administrativos</h2>
            <h5>Sistema para avaliação de documentos que se tornarão processos</h5>
            <hr size="10" width="20%" align="left" class="linha-horizontal">
        </div>
    </section>
    <section class="container mt-5 ">
        <h5> Processos atualmente disponíveis:</h5>
        <div class="container-features">
            <a class="link-indicacao" [routerLink]="['/indicacao']">
                <svg-icon class="icon-condutor" src="../../../assets/icon/condutor.svg"></svg-icon>
                Indicação de Condutor
            </a>

            <a class="link-indicacao" [routerLink]="['/recurso']" *ngIf="featureRecurso">
                <svg-icon class="icon-condutor" src="../../../assets/icon/law-icon.svg"></svg-icon>
                Processos
            </a>
        </div>
    </section>
    <p class="empresa-desenvolvedora">
        Desenvolvido com
        <svg-icon class="icon-coracao" src="../../../assets/icon/coracao.svg"></svg-icon>
        por<b> Serget</b>
    </p>
