<div class="container-home">
    <section class="container-blue">
        <div class="container d-flex">
            <div>
                <h2>
                    Processos
                    <svg-icon class="icon-condutor" src="../../../assets/icon/law-icon.svg"></svg-icon>
                </h2>
                <hr size="10" width="20%" align="left" class="linha-horizontal">
                <p>No painel você encontra os lotes com documentos que precisam ser avaliados.</p>
                <p>Ao terminar a avaliação de um lote, os processos serão efetivados no sistema de Processamento.</p>
            </div>
        </div>
    </section>
    <section class="modals">
        <app-modal-recurso-lotes  [lotesRecurso]="lotes" (loteIdSelecionado)="receberLoteSelecionado($event)" (recarregarLotes)="recarregarLotes($event)"></app-modal-recurso-lotes>
    </section>
</div>
