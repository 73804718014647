import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IndicacaoCondutor } from 'src/app/features/processo-de-validacao/models/informacao-lote';
import { IndicacaoService } from 'src/app/features/processo-de-validacao/service/indicacao.service';
import { TipoImagem } from 'src/app/shared/enums/TipoImagem';
import { Documento } from 'src/app/shared/interfaces/documento';
import { MultiCheckbox } from 'src/app/shared/interfaces/multi-checkbox';
import { Buffer} from 'buffer';
import { motivoArquivamentoService } from 'src/app/features/motivo-arquivamento/service/motivo-arquivamento.service';
import { MotivoArquivamento } from 'src/app/features/motivo-arquivamento/models/motivoArquivamento';
import { LoteService } from 'src/app/features/indicacao-condutor/services/lote.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-informacao-lote',
  templateUrl: './modal-informacao-lote.component.html',
  styleUrls: ['./modal-informacao-lote.component.scss']
})
export class ModalInformacaoLoteComponent implements OnInit {


  @Input()
  public indicacaoCondutores:  IndicacaoCondutor[];

  @Input()
  public loteId: number;

  public imagem1: string;
  public imagem2: string;
  public imagem3: string;
  public imagem4: string;
  public positionImagem = 0;
  public mostrarImagem1: boolean;
  public mostrarImagem2: boolean;
  public mostrarImagem3: boolean;
  public mostrarImagem4: boolean;
  public mostrarPdf1: boolean;
  public mostrarPdf2: boolean;
  public mostrarPdf3: boolean;
  public mostrarPdf4: boolean;
  public mostrarDoisArquivos:boolean;

  public indicacaoCondutorSelecionado: IndicacaoCondutor;
  public validators: FormGroup;
  public imagemSelect: string;
  public carregouImagens: boolean;
  public tipoImagem = TipoImagem;
  public checkDocumento: boolean = false;
  public motivosArquivamento: MotivoArquivamento[] = []

  public checkboxs: MultiCheckbox[] = [
    { name: "CNH", isCheck: false, value: 2  },
    { name: "PGU", isCheck: false, value: 1 },
    { name: "Internacional", isCheck: false, value: 3}
  ];

  public checkboxsFile: MultiCheckbox[] = []

  constructor( private formBuilder: FormBuilder,
    private indicacaoService: IndicacaoService,
    private motivoArquivamentoService: motivoArquivamentoService,
    private loteService: LoteService,
    private route: Router,
    private toastr: ToastrService)
  {
    this.validators = this.formBuilder.group(
      {
        nomeCondutor: ['', Validators.required],
        telefone: [''],
        email: [''],
        tipoDocumento: ['', Validators.required],
        numeroDocumento: ['', Validators.required],
        regiao: ['', [Validators.required, Validators.minLength(2)]],
        confirmacaoIndicacaoCondutor: ['', Validators.required],
        motivoArq: [0]
      },
      { updateOn: 'change' }
    );
  }

  public async  ngOnInit(): Promise<void> {
    await this.definirIndicacaoCondutorSelecionada();
    this.popularFormulario();
    await this.obterImagens();
    await this.obterMotivoArquivamento();
    this.validators.get('email')!.disable();
    this.validators.get('telefone')!.disable();
    this.updateRegiaoValidator();
    this.validators.get('tipoDocumento')!.valueChanges.subscribe(() => {
      this.updateRegiaoValidator();
    });
  }

  updateRegiaoValidator() {
    const isInternacionalChecked = this.checkboxs.some(checkbox => checkbox.name === 'Internacional' && checkbox.isCheck);
    const regiaoControl = this.validators.get('regiao')!

    if (isInternacionalChecked) {
      regiaoControl.clearValidators();
      regiaoControl.setValue(null);
      regiaoControl.disable();
    } else {
      regiaoControl.setValidators([Validators.required, Validators.minLength(2)]);
      regiaoControl.enable();

    }
    regiaoControl.updateValueAndValidity();
  }

  public async confirmarIndicacaoCondutor (): Promise<void> {
    const indicacao = {
      indicacaoId: this.indicacaoCondutorSelecionado.indicacaoID,
      motivoId: 0,
      nomeIndicado: this.validators.get('nomeCondutor')?.value,
      numeroCnh: this.validators.get('numeroDocumento')?.value,
      tipoCnh: this.validators.get('tipoDocumento')?.value,
      uFCnh: this.validators.get('regiao')?.value
    }

    try{
      await this.indicacaoService.postAceitarIndicacaoCondutor(indicacao);
      this.toastr.success('Indicação confirmada.')
    }catch(error) {
      this.toastr.error('Erro ao confirmar a indicação.')
    }

    await this.obterListaAtualizadaIndicacaoCondutores();
  }

  public async arquivarIndicacaoCondutor (): Promise<void> {
    const indicacao = {
      indicacaoId: this.indicacaoCondutorSelecionado.indicacaoID,
      motivoId: this.validators.get('motivoArq')?.value,
      nomeIndicado: this.validators.get('nomeCondutor')?.value,
      numeroCnh: this.validators.get('numeroDocumento')?.value,
      tipoCnh: this.validators.get('tipoDocumento')?.value,
      uFCnh: this.validators.get('regiao')?.value
    }

    try {
      await this.indicacaoService.postArquivarIndicacaoCondutor(indicacao);
      this.toastr.success('Indicação arquivada.')
    } catch(error) {
      this.toastr.error('Error ao arquivar a indicação.')
    }

    await this.obterListaAtualizadaIndicacaoCondutores();
  }

  public recebendoValueCheckbox(checkboxValue: any){
    this.checkboxs.forEach(check => {
      check.value === checkboxValue ?
        check.isCheck = !check.isCheck :
        check.isCheck = false
    });
    const checkboxIsTrue = this.checkboxs.filter(x => x.isCheck == true)
    if(checkboxIsTrue.length == 1) {
      this.validators.patchValue({ tipoDocumento: checkboxIsTrue[0].value})
    } else {
      this.validators.patchValue({ tipoDocumento: null});
    }
  }

  public receberValueCheckboxFile(checkboxValue: any) : void {
    this.limparImagens();
     this.checkboxsFile.forEach(check => {
      check.value === checkboxValue ?  check.isCheck = !check.isCheck : ''

      const checkboxIsTrue = this.checkboxsFile.filter(x => x.isCheck == true)
      this.mostrarDoisArquivos = checkboxIsTrue.length > 1;

      setTimeout(() => {
        check.isCheck === true ?
        this.mostrarImagemAmpliada(
          new Documento(check.value.base64, check.value.typeDocumento, check.value.tipoImagem))
          :
        this.removerImagemAmpliada(
          new Documento(check.value.base64, check.value.typeDocumento, check.value.tipoImagem));
      }, 500)
    });
  }

  public removerImagemAmpliada(arquivoVisualizacao: Documento): void {
    if (arquivoVisualizacao.tipoImagem === 2) {
      this.mostrarPdf2 = false;
      this.mostrarImagem2 = false;
      this.imagem2 = '';
    }
    else if (arquivoVisualizacao.tipoImagem === 3) {
      this.mostrarPdf3 = false;
      this.mostrarImagem3 = false;
      this.imagem3 = '';
    }
  }

  public mostrarImagemAmpliada(arquivoVisualizacao: Documento): void {
    if(arquivoVisualizacao.typeDocumento === "application/pdf") {
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = true;
        this.mostrarImagem2 = false;
        this.imagem2 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 3) {
        this.mostrarPdf3 = true;
        this.mostrarImagem3 = false;
        this.imagem3 = arquivoVisualizacao.base64;
      }
    }
    else {
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = false;
        this.mostrarImagem2 = true;
        this.imagem2 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 3) {
        this.mostrarPdf3 = false;
        this.mostrarImagem3 = true;
        this.imagem3 = arquivoVisualizacao.base64;
      }
    }
  }

  public RotacionarImagem(imagemId: string) : void {
    const containerImagem = document.getElementById(imagemId);
    const divElementImagem = containerImagem?.getElementsByTagName('div');
    const img = divElementImagem?.item(0);
    this.positionImagem = this.positionImagem + 90;
    img!.style.transform = `rotate(${this.positionImagem}deg)`;
  }


  public disableBtnAceite(): boolean {
    return this.validators.valid
  }

  private async definirIndicacaoCondutorSelecionada() : Promise<void> {
    this.indicacaoCondutores = this.indicacaoCondutores.filter( x => x.statusProcesso !== 2 )
    await this.fecharLote();
    this.indicacaoCondutorSelecionado = this.indicacaoCondutores[0];
  }

  private popularFormulario() {
    this.validators.patchValue({
      tipoDocumento: this.indicacaoCondutorSelecionado.habilitacao_Tipo,
      regiao: this.indicacaoCondutorSelecionado.habilitacao_UfDocumento,
      numeroDocumento: this.indicacaoCondutorSelecionado.habilitacao_NumeroDocumento,
      nomeCondutor: this.indicacaoCondutorSelecionado.nomeIndicado,
      email: this.indicacaoCondutorSelecionado.email,
      telefone: this.indicacaoCondutorSelecionado.telefone,
      confirmacaoIndicacaoCondutor: this.indicacaoCondutorSelecionado.indicacaoAceita ? true : false
    })
    this.checkboxs.forEach(check => {
      if(check.value === this.indicacaoCondutorSelecionado.habilitacao_Tipo){
        this.validators.patchValue({ tipoDocumento: check.value});
        check.isCheck = true;
      }
    });
  }

  private async obterImagens(): Promise<void> {
    this.checkboxsFile = []
    await this.obterImagemDocumento(this.indicacaoCondutorSelecionado.indicacaoID,
      2).then( resp => {
        if(resp) {
          this.checkboxsFile.push({name: '2', isCheck: false, value: resp});
        }
      })
    await this.obterImagemDocumento(this.indicacaoCondutorSelecionado.indicacaoID,
      3).then(resp => {
        if(resp) {
          this.checkboxsFile.push({name: '3', isCheck: false, value: resp});
        }
      });
      this.carregouImagens = true;
  }

  private async obterImagemDocumento(indicacaoId: number, type: TipoImagem): Promise<any> {
    try{
      const response = await this.indicacaoService.getImagensDeIndicacao(indicacaoId, type);
      const imagemDocumentoType = response.headers.get('Content-Type');
      let imagemDocumentoBase64 = Buffer.from(response.body, 'binary').toString('base64');
      if(imagemDocumentoType != 'application/pdf'){
        imagemDocumentoBase64 = `data:image/png;base64,${imagemDocumentoBase64}`
      }
      return new Documento(imagemDocumentoBase64, imagemDocumentoType, type)
    } catch(error) {
    }
  }

  private async obterListaAtualizadaIndicacaoCondutores(): Promise<void> {
    try{
      this.indicacaoCondutores = [];
      const responseApi = await this.indicacaoService.getProcessosDeValidacao(this.loteId);
      responseApi.listaIndicacoes.forEach((x: any) => {
        const lote = new IndicacaoCondutor(x)
        this.indicacaoCondutores.push(lote)
      })
    }catch(error){}
    await this.definirIndicacaoCondutorSelecionada();
    this.limparImagens();
    this.limparCheckbox();
    this.popularFormulario();
    await this.obterImagens();
  }

  private limparImagens(): void{
    this.mostrarPdf2 = false;
    this.mostrarImagem2 = false;
    this.imagem2 = "";

    this.mostrarPdf3 = false;
    this.mostrarImagem3 = false;
    this.imagem3 = "";
  }

  private limparCheckbox(): void {
    this.checkboxs = [];
    this.checkboxs = [
      { name: "CNH", isCheck: false, value: 2  },
      { name: "PGU", isCheck: false, value: 1 },
      { name: "Internacional", isCheck: false, value: 3}
    ];
  }

  private async obterMotivoArquivamento(): Promise<void> {
    try {
      const responseApi = await this.motivoArquivamentoService.getMotivoArquivamento();
      responseApi.listaMotivosArquivamento.forEach((x: any) => {
        const motivo = new MotivoArquivamento(x)
        this.motivosArquivamento.push(motivo)
      })
    }catch(error){

    }
  }

  private async fecharLote(): Promise<void> {
    if(this.indicacaoCondutores.length === 0 ){
      try {
        await this.loteService.fecharLote(this.loteId);
        this.route.navigate(['/indicacao']);
        this.toastr.success('Lote fechado.');
      } catch(error) {
        this.toastr.error('Error ao fechar o lote.')
      }
    }
  }


  private getEnumTipeDocumento(tipoDocumento: number): string {
    if(tipoDocumento == 1)
      return 'Documento identificador'

    if(tipoDocumento == 2)
      return 'CNH do infrator'

    if(tipoDocumento == 3)
      return 'Formulário Indicação'

    if(tipoDocumento == 4)
      return 'Documento de responsabilidade'

    return ''
  }

}
