import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageKeys } from 'src/app/core/enums/local-storage-keys';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UserService } from 'src/app/features/user/service/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu-logado',
  templateUrl: './menu-logado.component.html',
  styleUrls: ['./menu-logado.component.scss']
})
export class MenuLogadoComponent implements OnInit{

  @Input()
  public nomeUsuario: string;

  public featureRecurso: boolean;

  public mostrarSubtopicos: boolean = false;

  constructor(private userService: UserService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private activeRouter: ActivatedRoute) {
     }

  ngOnInit(): void {
    this.featureRecurso = environment.featureRecurso
  }

  public redefinirSenha(): void {
    this.router.navigate(["/redefinir-senha"]);
  }

  public async signout(): Promise<void> {
    this.router.navigate(["/home"]);
    await this.DeslogarSistemas();
  }

  private async DeslogarSistemas() :Promise<void> {
    try{
      await this.userService.postSignout();
      const token = this.localStorageService.get(LocalStorageKeys.id_token);
      this.localStorageService.clear();
      window.location.href = `${environment.auth.issuer}/connect/endsession?id_token_hint=${token}` +
      `&post_logout_redirect_uri=${environment.auth.postLogoutRedirectUri}`
    }catch(error){}
  }

  public obterLinkProcessamento() : void {
    window.location.href = environment.urlProcessamento
  }

  public obterLinkImpressao(): void {
    window.location.href = environment.urlImpressao
  }


  public mostrarSubtopicosMouseover(): void {
    this.mostrarSubtopicos = true;
  }
}
