<div class="imagens-documentos pt-2">
    <label for="documento">DOCUMENTOS</label>
    <div class="container-imagens d-flex pt-1">
        <div class="imagem" *ngFor="let checkbox of checkboxs">
            <img *ngIf="checkbox.value.typeDocumento !== 'application/pdf'" src="{{checkbox.value.base64}}" alt="">
            <svg-icon *ngIf="checkbox.value.typeDocumento === 'application/pdf'"  class="icon-archive"  src="../../../../assets/icon/pdf-icon.svg"></svg-icon>
            <div  [ngClass]="checkbox.value.typeDocumento !== 'application/pdf'? 'btn-imagem' : 'btn-icon' ">
                <input class="" type="checkbox" [(ngModel)]="checkbox.isCheck" (click)="emitEventCheckboxFile(checkbox)" [disabled]="checkbox.isCheck== false && desabilitarInput()">
            </div>
        </div>
    </div>
    <hr size="10" width="50%" align="center" class="linha-horizontal-form">
</div>
