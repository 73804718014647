import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { IndicacoesArquivadas } from 'src/app/features/indicacao-condutor/interfaces/indicacoes-arquivadas';
import { IndicacaoService } from 'src/app/features/processo-de-validacao/service/indicacao.service';
import { TipoImagem } from 'src/app/shared/enums/TipoImagem';
import { Documento } from 'src/app/shared/interfaces/documento';
import { MultiCheckbox } from 'src/app/shared/interfaces/multi-checkbox';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-modal-detalhes-indicacao-arquivada',
  templateUrl: './modal-detalhes-indicacao-arquivada.component.html',
  styleUrls: ['./modal-detalhes-indicacao-arquivada.component.scss']
})
export class ModalDetalhesIndicacaoArquivadaComponent implements OnInit {

  public checkboxsFile: MultiCheckbox[] = [];

  public detalheIndicacaoSelecionado: IndicacoesArquivadas;
  public loteId: number;

  public imagem3: string;
  public imagem2: string;
  public mostrarImagem3: boolean;
  public mostrarImagem2: boolean;
  public mostrarPdf3: boolean;
  public mostrarPdf2: boolean;
  public mostrarDoisArquivos:boolean

  public validators: FormGroup;
  public imagemSelect: string;
  public carregouImagens: boolean;
  public tipoImagem = TipoImagem;
  public checkDocumento: boolean = false;
  public positionImagem = 0;

  public tipoDocumento: any[] = [
    { name: "CNH", value: 2  },
    { name: "PGU", value: 1 },
    { name: "Internacional", value: 3}
  ];



  constructor(private router: Router, private route: ActivatedRoute, private indicacaoService: IndicacaoService) { }


  public async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(async (objeto: any) => {
      this.detalheIndicacaoSelecionado = objeto;
    });
    await this.obterImagens();
  }

  public TipoDocumento(tipoDocumentoId: number): string {
   const tipo =  this.tipoDocumento.filter(x => x.value == tipoDocumentoId)[0]
   return tipo.name;
  }

  public RotacionarImagem(imagemId: string) : void {
    const containerImagem = document.getElementById(imagemId);
    const divElementImagem = containerImagem?.getElementsByTagName('div');
    const img = divElementImagem?.item(0);
    this.positionImagem = this.positionImagem + 90;
    img!.style.transform = `rotate(${this.positionImagem}deg)`;
  }

  public mostrarImagemAmpliada(arquivoVisualizacao: Documento): void {
    if(arquivoVisualizacao.typeDocumento === "application/pdf") {
      if( arquivoVisualizacao.tipoImagem === 3){
        this.mostrarPdf3 = true;
        this.mostrarImagem3 = false;
        this.imagem3 = arquivoVisualizacao.base64;
      }
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = true;
        this.mostrarImagem2 = false;
        this.imagem2 = arquivoVisualizacao.base64;
      }
    }
    else {
      if( arquivoVisualizacao.tipoImagem === 3){
        this.mostrarPdf3 = false;
        this.mostrarImagem3 = true;
        this.imagem3 = arquivoVisualizacao.base64;
      }
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = false;
        this.mostrarImagem2 = true;
        this.imagem2 = arquivoVisualizacao.base64;
      }
    }
  }

  private async obterImagens(): Promise<void> {
    this.checkboxsFile = []
    await this.obterImagemDocumento(this.detalheIndicacaoSelecionado.recursoId,
      this.tipoImagem.CNHdoInfrator).then( resp => {
        if(resp) {
          this.checkboxsFile.push({name: this.tipoImagem.CNHdoInfrator.toString(), isCheck: false, value: resp});
        }
      })
    await this.obterImagemDocumento(this.detalheIndicacaoSelecionado.recursoId,
      this.tipoImagem.FormularioIndicacao).then(resp => {
        if(resp) {
          this.checkboxsFile.push({name: this.tipoImagem.FormularioIndicacao.toString(), isCheck: false, value: resp});
        }
      });
      this.carregouImagens = true;
  }

  private async obterImagemDocumento(
    indicacaoId: number,
    type: TipoImagem
  ): Promise<any> {
    try {
      const response = await this.indicacaoService.getImagensDeIndicacao(
        indicacaoId,
        type
      );
      const imagemDocumentoType = response.headers.get('Content-Type');
      let imagemDocumentoBase64 = Buffer.from(response.body, 'binary').toString(
        'base64'
      );
      if (imagemDocumentoType != 'application/pdf') {
        imagemDocumentoBase64 = `data:image/png;base64,${imagemDocumentoBase64}`;
        this.imagem2 = imagemDocumentoBase64
      }
      return new Documento(imagemDocumentoBase64, imagemDocumentoType, type);
    } catch (error) {
    }
  }

  public removerImagemAmpliada(arquivoVisualizacao: Documento): void {
    if (arquivoVisualizacao.tipoImagem === 3) {
      this.mostrarPdf3 = false;
      this.mostrarImagem3 = false;
      this.imagem3 = '';
      return
    }
    if (arquivoVisualizacao.tipoImagem === 2) {
      this.mostrarPdf2 = false;
      this.mostrarImagem2 = false;
      this.imagem2 = '';
      return
    }
  }

  private limparImagens(): void {
    this.mostrarPdf2 = false;
    this.mostrarImagem2 = false;
    this.imagem2 = "";

    this.mostrarPdf3 = false;
    this.mostrarImagem3 = false;
    this.imagem3 = "";
  }

  public receberValueCheckboxFile(checkboxValue: any): void {
    this.limparImagens();
    this.checkboxsFile.forEach((check) => {
      check.value === checkboxValue ? check.isCheck = true : check.isCheck = false;

      setTimeout(() => {
        check.isCheck === true
          ? this.mostrarImagemAmpliada(
              new Documento(
                check.value.base64,
                check.value.typeDocumento,
                check.value.tipoImagem
              )
            )
          : this.removerImagemAmpliada(
              new Documento(
                check.value.base64,
                check.value.typeDocumento,
                check.value.tipoImagem
              )
            );
      }, 500);
    });
  }

  public verificaDataTransmissao(indicacao: IndicacoesArquivadas): void {
    {queryParams: indicacao};
  }

  public openEdicaoIndicacao(indicacao: any): void {
    this.router.navigate(['/edicao-indicacao', indicacao]);
  }
}
