<div class="page"
  [ngClass]="{'page-full': pdfFullViewer}">
  <pdf-viewer
    [src]="src"
    [original-size]="true"
    [render-text]="true"
    [rotation]="0"
    [show-all]="false"
    [fit-to-page]="true"
    [zoom]="zoom"
    [zoom-scale]="'page-width'"
    [stick-to-page]="true"
    [rotation]="rotation"
    [external-link-target]="'blank'"
    [autoresize]="true"
    [show-borders]="false"
    [ngClass]="{
                'pdf-viewer': exibirDuasPdfs,
                'pdf-viewer-2': !exibirDuasPdfs,
              'pdf-full-viewer': pdfFullViewer
              }"
    [page]="page"
    (after-load-complete)="afterLoadComplete($event)"
  ></pdf-viewer>
  <section class="footer-pdf" *ngIf="isLoaded">
    <nav class="nav-pdf">
      <div class="container-previus-btn">
        <button (click)="prevPage()" [disabled]="page === 1" class="btn btn-secondary" >
          Anterior
        </button>
        </div>
        <div class="container-previus-btn">
        <button (click)="nextPage()" [disabled]="page === totalPages" class="btn btn-primary">
          Próximo
        </button>
      </div>
      <p class="contador-paginas">{{ page }} / {{ totalPages }}</p>
    </nav>
    <div class="container-zoom-btn">
      <button (click)="zoomIn()"><i class="bi bi-zoom-in"></i></button>
      <button (click)="zoomOut()"><i class="bi bi-zoom-out"></i></button>
      <button (click)="rotate(90)"><i class="bi bi-arrow-clockwise"></i></button>
    </div>
    <div class="container-download-btn">
      <button (click)="downloadPdf()" class="btn btn-success">
        Download PDF
      </button>
    </div>
  </section>
</div>