import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoteService } from 'src/app/features/indicacao-condutor/services/lote.service';
import { IndicacaoCondutor } from 'src/app/features/processo-de-validacao/models/informacao-lote';
import { IndicacaoService } from 'src/app/features/processo-de-validacao/service/indicacao.service';

@Component({
  selector: 'app-processo-de-validacao',
  templateUrl: './processo-de-validacao.component.html',
  styleUrls: ['./processo-de-validacao.component.scss']
})
export class ProcessoDeValidacaoComponent implements OnInit {

  public loteId: number;
  public loteSelecionado: IndicacaoCondutor[] = []
  public carregouLote: boolean

  constructor(private route: ActivatedRoute, 
    private indicacaoService: IndicacaoService,
    private loteService: LoteService,
    private toastr: ToastrService) { }


  public async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (objeto: any) => {
      this.loteId = objeto.id;
      await this.obterProcessosDeValidacao(objeto.id);
    })
  }

  public async desvincularLoteAoUsuario(): Promise<void> {
    await this.loteService.desvincularLoteAoUsuario(this.loteId);
  }
  
  private async obterProcessosDeValidacao(loteId: number): Promise<void> {
    try{
      const responseApi = await this.indicacaoService.getProcessosDeValidacao(loteId);
      responseApi.listaIndicacoes.forEach((x: any) => {
        const lote = new IndicacaoCondutor(x);
        this.loteSelecionado.push(lote);
      })
      this.carregouLote = true;

    }catch(error) {
      this.toastr.error('Erro ao obter uma lista de Indicações do lote.');
    }
  }
}
