import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from 'src/environments/environment';

@Injectable()
export class RecursoAuthGuard implements CanActivate {

    constructor() { }

    public canActivate() : any {
        return environment.featureRecurso;
    }

}