import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LocalStorageKeys } from 'src/app/core/enums/local-storage-keys';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LotesIndicacao } from 'src/app/features/indicacao-condutor/interfaces/lotes';

@Component({
  selector: 'app-modal-lotes',
  templateUrl: './modal-lotes.component.html',
  styleUrls: ['./modal-lotes.component.scss']
})
export class ModalLotesComponent implements OnChanges  {

  @Input()
  public lotesIndicacao: LotesIndicacao[];

  @Output()
  public loteIdSelecionado = new EventEmitter<number>();

  @Output()
  public recarregarLotes = new EventEmitter<any>();

  public loteID: string;

  public lotes: LotesIndicacao[];

  constructor(private localStorageService: LocalStorageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.lotes = this.lotesIndicacao;
  }

  public search() {
    if (this.loteID === '') {
      this.lotes = this.lotesIndicacao;
      return;
    }
    this.lotes = this.lotesIndicacao.filter((response) => {
      return String(response.loteID).includes(this.loteID)
    });
  }
  public selectLote(lote: LotesIndicacao): void {
    this.loteIdSelecionado.emit(lote.loteID);
  }

  public atualizarLotes(): void {
    this.recarregarLotes.emit();
  }

  public loteEmAndamento(lote: LotesIndicacao): boolean {
    const usuarioId = parseInt(this.localStorageService.get(LocalStorageKeys.userId));
    const temDataHoraVinculado = lote.dataHoraUsuarioVinculado
    if(temDataHoraVinculado) {
      let dataUsuarioFoiVinculado = new Date(lote.dataHoraUsuarioVinculado)
      const dataMaximaDoVinculo = dataUsuarioFoiVinculado.setHours(dataUsuarioFoiVinculado.getHours() + 1);
      var dataMaximaUsuarioVinculado = new Date(dataMaximaDoVinculo);
      const dateNow = new Date();

      if(dateNow > dataMaximaUsuarioVinculado) {
        return true;
      }
    }

    if(lote.usuarioVinculado == null || lote.usuarioVinculado === usuarioId || temDataHoraVinculado == null ){
      return true;
    }
    
    return false;
  }
}
