export class IndicacaoCondutor {
    ait: string;
    anoProcesso: number; 
    cdInfracao: number;
    dataCriacao: Date;
    dataEntrada: Date;
    dataLimiteIndicacao: Date;
    docInteressado_CpfCnpj: string;
    docInteressado_TipoDocumento: number;
    habilitacao_NumeroDocumento: string;
    habilitacao_Tipo: number;
    habilitacao_UfDocumento: string;
    imagemDocProprietario: string;
    imagemDocResponsabilidade: string;
    imagemFormulario: string;
    imagemHabilitacaoCondutor: string;
    indicacaoAceita: boolean;
    indicacaoID: number;
    interessado_Nome: string;
    motivoArquivamentoID: number;
    nomeIndicado: string;
    numeroProcesso: number;
    orgaoAutuador: number;
    placaVeiculo: string;
    possuiAssinaturaCondutor: boolean;
    statusProcesso: number;
    tipoProcesso: number;
    telefone: number;
    email: string;


    constructor(response: any){
        this.ait = response.ait,
        this.anoProcesso = response.anoProcesso,
        this.cdInfracao = response.cdInfracao,
        this.dataCriacao = response.dataCriacao,
        this.dataEntrada = response.dataEntrada,
        this.dataLimiteIndicacao = response.dataLimiteIndicacao,
        this.docInteressado_CpfCnpj = response.docInteressado_CpfCnpj,
        this.docInteressado_TipoDocumento = response.docInteressado_TipoDocumento,
        this.habilitacao_NumeroDocumento = response.habilitacao_NumeroDocumento,
        this.habilitacao_Tipo = response.habilitacao_Tipo,
        this.habilitacao_UfDocumento = response.habilitacao_UfDocumento,
        this.imagemDocProprietario = response.imagemDocProprietario,
        this.imagemDocResponsabilidade = response.imagemDocResponsabilidade,
        this.imagemFormulario = response.imagemFormulario,
        this.imagemHabilitacaoCondutor = response.imagemHabilitacaoCondutor,
        this.indicacaoAceita = response?.indicacaoAceita,
        this.indicacaoID = response.indicacaoID,
        this.interessado_Nome = response.interessado_Nome,
        this.motivoArquivamentoID = response.motivoArquivamentoID,
        this.nomeIndicado = response.nomeIndicado,
        this.numeroProcesso = response.numeroProcesso,
        this.orgaoAutuador = response.orgaoAutuador,
        this.placaVeiculo = response.placaVeiculo,
        this.possuiAssinaturaCondutor = response.possuiAssinaturaCondutor,
        this.statusProcesso = response.statusProcesso,
        this.tipoProcesso = response.tipoProcesso,
        this.telefone = response.celularCondutor,
        this.email = response.emailCondutor
    };
}