export class RecursoArquivado {
    nomeInteressado: string;
    recursoId: number;
    statusProcesso: number;
    tipoRecurso: string;
    autoInfracao: string;
    placa: string;
    dataProtocolo: Date;
    dataArquivamento: Date;
    nrProtocolo: string;
    lote: number;
    motivoArquivamento: string;
    userAvaliacaoNome: string;
    usuarioAvaliacaoId: number;
    telefone: number;
    email: string;
    dataTransmissao: Date;
    dataAnalise: Date;


    constructor(response: any){
    this.nomeInteressado = response.nomeInteressado;
    this.recursoId = response.id;
    this.statusProcesso = response.statusProcesso;
    this.tipoRecurso = response.tipo;
    this.nrProtocolo = response.numeroProtocolo;
    this.dataProtocolo = response.dataCriacao;
    this.dataArquivamento = response.dataAnalise;
    this.placa = response.placa;
    this.autoInfracao = response.autoInfracao;
    this.lote = response.loteId;
    this.motivoArquivamento = response.motivoArquivamentoRecurso.motivo
    this.usuarioAvaliacaoId = response.usuarioAvaliacao;
    this.telefone = response.celularInteressado;
    this.email = response.emailInteressado;
    this.dataTransmissao = response.dataTransmissao;
    this.dataAnalise = response.dataAnalise;
    }

}
