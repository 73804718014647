<section class="modal-informacao-lote containers d-flex">
    <div class="container-imagem">
        <div  *ngIf="mostrarImagem1" class="container-imagem2" id="primeiraImagem">
            <lib-ngx-image-zoom
                *ngIf="mostrarImagem1"
                [ngClass]="mostrarDoisArquivos?
                    'duas-imagem-zoom' : 'uma-imagem-zoom' "
                [thumbImage]=imagem1
                [fullImage]=imagem1
                [magnification]="1"
                [enableScrollZoom]="true"
                [enableLens]="true"
                [lensWidth]="250"
                [lensHeight]="200"
                [zoomMode]="'toggle-click'"
                [scrollStepSize]="0.1"
            ></lib-ngx-image-zoom >
            <button class="btn-rotation" (click)="RotacionarImagem('primeiraImagem')"><i class="bi bi-arrow-clockwise"></i></button>
        </div>
        <div  *ngIf="mostrarImagem2" class="container-imagem2" id="segundaImagem">
            <lib-ngx-image-zoom
                *ngIf="mostrarImagem2"
                [ngClass]="mostrarDoisArquivos?
                    'duas-imagem-zoom' : 'uma-imagem-zoom' "
                [thumbImage]=imagem2
                [fullImage]=imagem2
                [magnification]="1"
                [enableScrollZoom]="true"
                [enableLens]="true"
                [lensWidth]="250"
                [lensHeight]="200"
                [zoomMode]="'toggle-click'"
                [scrollStepSize]="0.1"
            ></lib-ngx-image-zoom >
            <button class="btn-rotation" (click)="RotacionarImagem('segundaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
        </div>
        <div  *ngIf="mostrarImagem3" class="container-imagem2" id="terceiraImagem">
            <lib-ngx-image-zoom
                *ngIf="mostrarImagem3"
                [ngClass]="mostrarDoisArquivos?
                    'duas-imagem-zoom' : 'uma-imagem-zoom' "
                [thumbImage]=imagem3
                [fullImage]=imagem3
                [magnification]="1"
                [enableScrollZoom]="true"
                [enableLens]="true"
                [lensWidth]="250"
                [lensHeight]="200"
                [zoomMode]="'toggle-click'"
                [scrollStepSize]="0.1"
            ></lib-ngx-image-zoom >
            <button class="btn-rotation" (click)="RotacionarImagem('terceiraImagem')"><i class="bi bi-arrow-clockwise"></i></button>
        </div>
        <div  *ngIf="mostrarImagem4" class="container-imagem2" id="quartaImagem">
            <lib-ngx-image-zoom
                *ngIf="mostrarImagem4"
                [ngClass]="mostrarDoisArquivos?
                    'duas-imagem-zoom' : 'uma-imagem-zoom' "
                [thumbImage]=imagem4
                [fullImage]=imagem4
                [magnification]="1"
                [enableScrollZoom]="true"
                [enableLens]="true"
                [lensWidth]="250"
                [lensHeight]="200"
                [zoomMode]="'toggle-click'"
                [scrollStepSize]="0.1"
            ></lib-ngx-image-zoom >
            <button class="btn-rotation" (click)="RotacionarImagem('quartaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
        </div>
        <div  *ngIf="mostrarImagem5" class="container-imagem2" id="quintaImagem">
          <lib-ngx-image-zoom
              *ngIf="mostrarImagem5"
              [ngClass]="mostrarDoisArquivos?
                  'duas-imagem-zoom' : 'uma-imagem-zoom' "
              [thumbImage]=imagem5
              [fullImage]=imagem5
              [magnification]="1"
              [enableScrollZoom]="true"
              [enableLens]="true"
              [lensWidth]="250"
              [lensHeight]="200"
              [zoomMode]="'toggle-click'"
              [scrollStepSize]="0.1"
          ></lib-ngx-image-zoom >
          <button class="btn-rotation" (click)="RotacionarImagem('quintaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
      </div>
        <app-pdf-viewer *ngIf="mostrarPdf1" [base64Pdf]="imagem1" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
        <app-pdf-viewer *ngIf="mostrarPdf2" [base64Pdf]="imagem2" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
        <app-pdf-viewer *ngIf="mostrarPdf3" [base64Pdf]="imagem3" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
        <app-pdf-viewer *ngIf="mostrarPdf4" [base64Pdf]="imagem4" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
        <app-pdf-viewer *ngIf="mostrarPdf5" [base64Pdf]="imagem5" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
    </div>
    <div *ngIf="recursoSelecionado" class="modal-informacao">
        <div class="d-flex informacao-infracao">
            <div class="card">
                <p>Auto Infração</p>
                <P>{{recursoSelecionado.autoInfracao}}</P>
            </div>
            <div class="card">
                <p>PLACA</p>
                <P>{{recursoSelecionado.placa}}</P>
            </div>
            <div class="card">
                <p>N protocolo</p>
                <P>{{recursoSelecionado.nrProtocolo}}</P>
            </div>
            <div class="card">
                <p>Data protocolo</p>
                <P>{{recursoSelecionado.dataProtocolo | date : 'dd/MM/yyyy' }}</P>
            </div>
        </div>

        <div class="formulario">
            <form action="" [formGroup]="validators" class="g-3">
                <div class="pt-3 col-md-12">
                    <label for="nomeInteressado" class="form-label">Nome do interessado</label>
                    <input type="text" class="form-control" formControlName="nomeInteressado" required>
                    <small *ngIf="validators.get('nomeInteressado')?.hasError('required')">
                        Insira o nome do interessado.
                    </small>
                </div>
                <div class="pt-3 col-md-12">
                    <label for="email" class="form-label">Email</label>
                    <input type="text" class="form-control" formControlName="email" [disabled]="true">
                </div>
                <div class="pt-3 col-md-12">
                    <label for="telefone" class="form-label">Telefone</label>
                    <input type="text" class="form-control" formControlName="telefone">
                </div>
                <div class="tipo-doc pt-2" >
                    <label>Tipo de processo</label>
                    <select class="form-select" formControlName="tipoProcesso" id="tipoProcesso">
                        <option  *ngFor="let processo of tipoProcessos" [ngValue]="processo.value">{{processo.key}}</option>
                    </select>

                    <small *ngIf="validators.get('tipoRecurso')?.hasError('required')">
                        Selecione um tipo de processo.
                    </small>
                </div>
                <app-multi-checkbox-file *ngIf="carregouImagens" [checkboxs]="checkboxsFile" (checkboxSelectFileEmit)="receberValueCheckboxFile($event)"></app-multi-checkbox-file>
                <div class="footer-form">
                    <div class="form-check-change form-check form-switch">
                        <input class="form-check-input"  id="checkDocumentos" type="checkbox" role="switch" formControlName="confirmacaoRecurso" required>
                        <label class="form-check-label" for="checkDocumentos"> Conferi todos os documentos</label>
                    </div>
                    <div class="btn-form">
                        <button class="btn-confirmed" type="submit" (click)="confirmarRecurso()" [disabled]="!disableBtnAceite()">
                            <i class="bi bi-check-circle"></i>
                            Aceitar
                        </button>
                        <button class="btn-arq" type="submit"   data-bs-toggle="modal" data-bs-target="#exampleModal" [disabled]="!disableBtnAceite()">
                            <svg-icon class="icon-archive" src="../../../../../assets/icon/archive-icon-white.svg"></svg-icon>
                            Arquivar
                        </button>
                    </div>
                </div>

                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">Motivo Arquivamento</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <label for="tipoRelatorio">Motivo</label>
                            <select class="form-select" formControlName="motivoArq" id="motivoArq">
                                <option  *ngFor="let motivo of motivosArquivamento" [ngValue]="motivo.codigoMotivo">{{motivo.descricaoMotivo}}</option>
                            </select>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="arquivarRecurso()">Salvar Motivo</button>
                        </div>
                      </div>
                    </div>
                  </div>

            </form>
        </div>
    </div>
</section>
