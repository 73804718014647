import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IndicacoesArquivadas } from 'src/app/features/indicacao-condutor/interfaces/indicacoes-arquivadas';
import { IndicacaoService } from 'src/app/features/processo-de-validacao/service/indicacao.service';
import { UserService } from 'src/app/features/user/service/user.service';

@Component({
  selector: 'app-indicacoes-arquivadas',
  templateUrl: './indicacoes-arquivadas.component.html',
  styleUrls: ['./indicacoes-arquivadas.component.scss']
})
export class IndicacoesArquivadasComponent implements OnInit {
  public validators: FormGroup;
  public totalizador: number;
  public indicacoes: IndicacoesArquivadas[] = [];
  public page: number = 1;
  public take: 5;
  public sortDirection = 1; // 1 pra ascendente, -1 pra descendente
  public currentColumn = '';

  // public tipoDocumento: any[] = [
  //   { name: "CNH", value: 2  },
  //   { name: "PGU", value: 1 },
  //   { name: "Internacional", value: 3}
  // ];


  public takeFilter: number[] = [
    5,
    10,
    15,
    25,
    30,
    35,
    40,
    45,
    50
  ]

  constructor( private indicacaoService : IndicacaoService,
    private route: Router,
    private usuarioService: UserService,
    private formBuilder: FormBuilder,) {
    this.validators = this.formBuilder.group(
      {
        dataInicial: ['', Validators.required],
        dataFinal: ['', Validators.required],
        tipoDocumento: ['', Validators.required],
        termo: ['', Validators.required],
        take: [5, Validators.required],
        statusTransmissao: ['todos', Validators.required],
        statusIndicacao: ['todos', Validators.required],
      },
      { updateOn: 'change' }
    );
   }

  public async ngOnInit(): Promise<void> {

    await this.obterListagemArquivadas();
  }

  // public retornarTipoDeProcesso(value: string) : string {
  //   return this.tipoDocumento.filter(x => x.value == value)[0].key;
  // }

  public async onSubmit(): Promise<void> {
    this.indicacoes = [];
    this.page = 1;
    await this.consultarRecusosArquivados();
  }

  public openEdicaoIndicacao(indicacao: IndicacoesArquivadas): void {
    this.route.navigate(['/edicao-indicacao', indicacao.recursoId]);
  }

  public openDetalhamentoIndicacao(indicacao: IndicacoesArquivadas): void {
    this.route.navigate(['/detalhe-indicacao-arquivada'],
    {queryParams: indicacao});
  }

  private async obterListagemArquivadas(): Promise<void> {
    const dataFinal  = new Date();
    const dateAfter = new  Date();
    dateAfter.setDate(dateAfter.getDate() - 30);
    const dataInicial = dateAfter;
    this.validators.patchValue({
      // tipoDocumento: this.tipoDocumento[0].value,
      dataInicial: dataInicial,
      dataFinal: dataFinal
    })
    await this.consultarRecusosArquivados();
  }


  private async consultarRecusosArquivados() : Promise<void> {
    const termo = this.validators.get('termo')?.value;
    // const tipoProcesso = this.validators.get('tipoDocumento')?.value;
    const dataInicial =  this.validators.get('dataInicial')?.value;
    const dataFinal =  this.validators.get('dataFinal')?.value;
    this.take =  this.validators.get('take')?.value;
    var statusTransmissao = this.validators.get('statusTransmissao')?.value;
    var statusIndicacao = this.validators.get('statusIndicacao')?.value;

    const result = await this.indicacaoService.getIndicacoesArquivadas(dataInicial.toDateString(), dataFinal.toDateString(), termo, 0, 1000, statusTransmissao, statusIndicacao);
    // tipoProcesso
    result.indicacao.forEach((x: any) => {
      const lote = new IndicacoesArquivadas(x);
      this.indicacoes.push(lote);
    });
    this.totalizador = result.totalizador;
  }

  sortTable(column: string) {
    if (this.currentColumn === column) {
      this.sortDirection *= -1;
    } else {
      this.sortDirection = 1;
    }
    this.currentColumn = column;

    this.indicacoes.sort((a: any, b: any) => {
      let valA = a[column];
      let valB = b[column];

      if (valA  && !valB) {
        return this.sortDirection === 1 ? -1 : 1;
      } else if (!valA  && valB) {
        return this.sortDirection === 1 ? 1 : -1;
      }

      if (this.isISODate(valA) && this.isISODate(valB)) {
        valA = new Date(valA);
        valB = new Date(valB);
      }
      if (!isNaN(parseFloat(valA)) && !isNaN(parseFloat(valB)) && !this.isISODate(valA) && !this.isISODate(valB)) {
        valA = parseFloat(valA);
        valB = parseFloat(valB);
      }

      if (valA < valB) {
        return -1 * this.sortDirection;
      } else if (valA > valB) {
        return 1 * this.sortDirection;
      }
      return 0;
    });
  }

  isISODate(value: any): boolean {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/.test(value);
  }

  getSortIcon(column: string): string {
    if (this.currentColumn !== column) {
      return '';
    }
    return this.sortDirection === 1 ? 'bi-chevron-up' : 'bi-chevron-down';
  }
}
