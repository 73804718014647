<div class="container-home">
    <section class="container-blue">
        <div class="container d-flex">
            <div>
                <h2>
                    Alteração de indicação
                </h2>
                <p>
                    <i class="bi bi-chevron-right"></i>
                    Indicações
                    <i class="bi bi-chevron-right"></i>
                    Alterar Indicações
                </p>
            </div>
            <button class="btn-arquivadas" type="button" class="btn" [routerLink]="['/indicacoes-arquivadas']" >  
                <i class="bi bi-arrow-return-left"></i>
                Voltar
            </button>
        </div>
    </section>
    <section class="modals">
        <section class="modal-informacao-lote containers d-flex">
            <div class="container-imagem">
                <div  *ngIf="mostrarImagem1" class="container-imagem2" id="primeiraImagem">
                    <lib-ngx-image-zoom
                        *ngIf="mostrarImagem1"
                        [ngClass]="mostrarDoisArquivos?
                            'duas-imagem-zoom' : 'uma-imagem-zoom' "
                        [thumbImage]=imagem1
                        [fullImage]=imagem1
                        [magnification]="1"
                        [enableScrollZoom]="true"
                        [enableLens]="true"
                        [lensWidth]="250"
                        [lensHeight]="200"
                        [zoomMode]="'toggle-click'"
                        [scrollStepSize]="0.1"
                    ></lib-ngx-image-zoom >
                    <button class="btn-rotation" (click)="RotacionarImagem('primeiraImagem')"><i class="bi bi-arrow-clockwise"></i></button>
                </div>
                <div  *ngIf="mostrarImagem2" class="container-imagem2" id="segundaImagem">
                    <lib-ngx-image-zoom
                        *ngIf="mostrarImagem2"
                        [ngClass]="mostrarDoisArquivos?
                            'duas-imagem-zoom' : 'uma-imagem-zoom' "
                        [thumbImage]=imagem2
                        [fullImage]=imagem2
                        [magnification]="1"
                        [enableScrollZoom]="true"
                        [enableLens]="true"
                        [lensWidth]="250"
                        [lensHeight]="200"
                        [zoomMode]="'toggle-click'"
                        [scrollStepSize]="0.1"
                    ></lib-ngx-image-zoom >
                    <button class="btn-rotation" (click)="RotacionarImagem('segundaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
                </div>
                <div  *ngIf="mostrarImagem3" class="container-imagem2" id="terceiraImagem">
                    <lib-ngx-image-zoom
                        *ngIf="mostrarImagem3"
                        [ngClass]="mostrarDoisArquivos?
                            'duas-imagem-zoom' : 'uma-imagem-zoom' "
                        [thumbImage]=imagem3
                        [fullImage]=imagem3
                        [magnification]="1"
                        [enableScrollZoom]="true"
                        [enableLens]="true"
                        [lensWidth]="250"
                        [lensHeight]="200"
                        [zoomMode]="'toggle-click'"
                        [scrollStepSize]="0.1"
                    ></lib-ngx-image-zoom >
                    <button class="btn-rotation" (click)="RotacionarImagem('terceiraImagem')"><i class="bi bi-arrow-clockwise"></i></button>
                </div>
                <div  *ngIf="mostrarImagem4" class="container-imagem2" id="quartaImagem">
                    <lib-ngx-image-zoom
                        *ngIf="mostrarImagem4"
                        [ngClass]="mostrarDoisArquivos?
                            'duas-imagem-zoom' : 'uma-imagem-zoom' "
                        [thumbImage]=imagem4
                        [fullImage]=imagem4
                        [magnification]="1"
                        [enableScrollZoom]="true"
                        [enableLens]="true"
                        [lensWidth]="250"
                        [lensHeight]="200"
                        [zoomMode]="'toggle-click'"
                        [scrollStepSize]="0.1"
                    ></lib-ngx-image-zoom >
                    <button class="btn-rotation" (click)="RotacionarImagem('quartaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
                </div>
                <app-pdf-viewer *ngIf="mostrarPdf1" [base64Pdf]="imagem1" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
                <app-pdf-viewer *ngIf="mostrarPdf2" [base64Pdf]="imagem2" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
                <app-pdf-viewer *ngIf="mostrarPdf3" [base64Pdf]="imagem3" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
                <app-pdf-viewer *ngIf="mostrarPdf4" [base64Pdf]="imagem4" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
            </div>
            <div *ngIf="indicacaoCondutorSelecionado" class="modal-informacao">
                <div class="d-flex informacao-infracao">
                    <div class="card">
                        <p>AIT</p>
                        <p>{{indicacaoCondutorSelecionado.ait}}</p>
                    </div>
                    <div class="card">
                        <p>PLACA</p>
                        <p>{{indicacaoCondutorSelecionado.placaVeiculo}}</p>
                    </div>
                    <div class="card">
                        <p>PROTOCOLO</p>
                        <p>{{indicacaoCondutorSelecionado.protocolo}}</p>
                    </div>
                </div>

                <div class="formulario">
                    <form action="" [formGroup]="validators" class="g-3">
                        <div class="d-flex pt-2">
                            <div class="col-md-6 input-documento">
                                <label for="dataEntrada" class="form-label">Data de entrada</label>
                                <input type="text" class="form-control" formControlName="dataEntrada">
                            </div>
                            <div class="col-md-6">
                                <label for="dataAnalise" class="form-label">Data de análise</label>
                                <input type="text" class="form-control" formControlName="dataAnalise">
                            </div>
                        </div>
                        <div class="pt-3 col-md-12">
                            <label for="nomeCondutor" class="form-label">Nome do condutor</label>
                            <input type="text" class="form-control" formControlName="nomeCondutor">
                        </div>
                        <div class="pt-3 col-md-12">
                            <label for="email" class="form-label">Email</label>
                            <input type="text" class="form-control" formControlName="email" maxlength="100">
                        </div>
                        <div class="d-flex pt-2">
                            <div class="col-md-6 input-documento">
                                <label for="telefone" class="form-label">Telefone</label>
                                <input type="text" maxlength="11" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control"s formControlName="telefone">
                            </div>
                            <div class="col-md-6">
                                <label for="numeroLote" class="form-label">Lote</label>
                                <input type="text" class="form-control" formControlName="numeroLote" >
                            </div>
                        </div>
                        <div class="pt-3 col-md-12">
                            <label for="usuarioAvaliacao" class="form-label">Usuário avaliação</label>
                            <input type="text" class="form-control" formControlName="usuarioAvaliacao">
                        </div>
                        <div class="tipo-doc pt-2" >
                            <label>Tipo de documento</label>
                            <app-multi-checkbox [checkboxs]="checkboxs" (checkboxSelectEmit)="recebendoValueCheckbox($event)"></app-multi-checkbox>
                            <small *ngIf="validators.get('tipoDocumento')?.hasError('required')">
                                Selecione um tipo de documento.
                            </small>
                        </div>
                        <div class="d-flex pt-2">
                            <div class="col-md-8 input-documento">
                                <label for="valorDocumento" class="form-label">CNH</label>
                                <input type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required maxlength="15" minlength="10" class="form-control" id="valorDocumento" formControlName="numeroDocumento" required >
                                <small *ngIf="validators.get('numeroDocumento')?.hasError('required')">
                                    Insira a CNH do condutor.
                                </small>
                                <small *ngIf="validators.get('numeroDocumento')?.hasError('minlength')">
                                    O número mínimo de dígitos é 10.
                                </small>
                            </div>
                            <div class="col-md-3">
                                <label for="regiao" class="form-label">UF</label>
                                <select class="form-control" id="estado" formControlName= "regiao" [(ngModel)]="estadoSelecionado">
                                    <option *ngFor="let estado of estados" [value]="estado">{{estado}}</option>
                                </select>
                                <small *ngIf="validators.get('regiao')?.hasError('required')">
                                    Insira o estado do condutor.
                                </small>
                                <small *ngIf="validators.get('regiao')?.hasError('minlength')">
                                    A UF é inválida.
                                </small>
                            </div>
                        </div>
                        <div class="pt-3 col-md-12">
                            <div class="form-check-change form-check form-switch">
                                <input class="form-check-input"  id="checkIndicacaoAceita" type="checkbox" role="switch" formControlName="indicacaoAceita" required>
                                <label class="form-check-label" for="checkIndicacaoAceita"> Indicação aceita</label>
                            </div>
                        </div>
                        <div *ngIf="!checkboxIndicacaoValida" class="pt-3 col-md-12">
                            <label for="motivoArquivamento" class="form-label">Motivo de arquivamento</label>
                            <select type="text" class="form-control" formControlName="motivoArquivamento">
                                <option *ngFor="let motivoArquivamento of motivosArquivamento" [value]="motivoArquivamento.codigoMotivo">{{motivoArquivamento.descricaoMotivo}}</option>
                            </select>
                        </div>
                        <app-multi-checkbox-file *ngIf="carregouImagens" [checkboxs]="checkboxsFile" (checkboxSelectFileEmit)="receberValueCheckboxFile($event)"></app-multi-checkbox-file>
                        <div class="footer-form">
                            <div class="form-check-change form-check form-switch">
                                <input class="form-check-input" id="checkDocumentos" type="checkbox" role="switch" formControlName="confirmacaoIndicacaoCondutor" required>
                                <label class="form-check-label" for="checkDocumentos"> Conferi todos os documentos</label>
                            </div>
                            <div class="btn-form">
                                <button class="btn-confirmed" type="submit" [disabled]="!disableBtnAceite()" (click)="validarIndicacao()" >
                                    <i class="bi bi-check-circle"></i>
                                    Atualizar
                                </button>
                            </div>
                            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Alterar Motivo de Arquivamento</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label> {{textoConfirmacaoModal}}</label>
                                    </div>
                                    <div class="modal-footer btn-form">
                                        <button type="button" class="btn-arq" data-bs-dismiss="modal">Cancelar</button>
                                        <button type="button" class="btn-confirmed" data-bs-dismiss="modal" (click)="atualizarIndicacaoCondutor()">Confirmar</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
</section>
</div>