import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UserService } from 'src/app/features/user/service/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public featureRecurso: boolean;
  public exist: boolean;


  constructor(private router: Router) { }
  
  ngOnInit(): void {
    this.featureRecurso = environment.featureRecurso;
    this.exist = environment.possui;
  }

  public login() : void {
    this.router.navigate(["/indicacao"])
  }

}
