import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public response: any = [];
  public featureRecurso: boolean;

  constructor(public service: BaseService) { }

  ngOnInit(): void {
    this.featureRecurso = environment.featureRecurso;
  }
  
}
