import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Recurso } from 'src/app/features/recursos/models/recurso';
import { RecursoService } from 'src/app/features/recursos/service/recurso.service';
import { TipoImagem } from 'src/app/shared/enums/TipoImagem';
import { Documento } from 'src/app/shared/interfaces/documento';
import { MultiCheckbox } from 'src/app/shared/interfaces/multi-checkbox';
import { Buffer} from 'buffer';
import { MotivoArquivamento } from 'src/app/features/motivo-arquivamento/models/motivoArquivamento';
import { motivoArquivamentoService } from 'src/app/features/motivo-arquivamento/service/motivo-arquivamento.service';
import { IndicacaoCondutor } from 'src/app/features/processo-de-validacao/models/informacao-lote';
import { LoteService } from 'src/app/features/indicacao-condutor/services/lote.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-informacao-recurso',
  templateUrl: './modal-informacao-recurso.component.html',
  styleUrls: ['./modal-informacao-recurso.component.scss']
})
export class ModalInformacaoRecursoComponent implements OnInit {
  @Input()
  public recursos:  Recurso[];

  @Input()
  public loteId: number;

  public imagem1: string;
  public imagem2: string;
  public imagem3: string;
  public imagem4: string;
  public imagem5: string;
  public mostrarImagem1: boolean;
  public mostrarImagem2: boolean;
  public mostrarImagem3: boolean;
  public mostrarImagem4: boolean;
  public mostrarImagem5: boolean;
  public mostrarPdf1: boolean;
  public mostrarPdf2: boolean;
  public mostrarPdf3: boolean;
  public mostrarPdf4: boolean;
  public mostrarPdf5: boolean;
  public mostrarDoisArquivos:boolean;
  public positionImagem = 0;

  public recursoSelecionado: Recurso;
  public validators: FormGroup;
  public imagemSelect: string;
  public carregouImagens: boolean;
  public tipoImagem = TipoImagem;
  public checkDocumento: boolean = false;
  public motivosArquivamento: MotivoArquivamento[] = []

  public tipoProcessos: any[] = [
    {value: "A", key: "Advertência"},
    {value: "P", key: "Defesa de autuação"},
    {value: "J", key: "Jari"},
    {value: "C", key: "Cetran"}
  ]

  public checkboxsFile: MultiCheckbox[] = []

  constructor(private formBuilder: FormBuilder,
    private recursoService: RecursoService,
    private motivoArquivamentoService: motivoArquivamentoService,
    private loteService: LoteService,
    private route: Router,
    private toastr: ToastrService) {
    this.validators = this.formBuilder.group(
      {
        nomeInteressado: ['', Validators.required],
        telefone: ['', ],
        email: [''],
        tipoProcesso: ['', Validators.required],
        motivoArq: [0],
        confirmacaoRecurso: ['', Validators.required]
      },
      { updateOn: 'change' }
    );
   }

  public async ngOnInit(): Promise<void> {
    await this.definirRecursoSelecionada();
    this.popularFormulario();
    await this.obterImagens();
    await this.obterMotivoArquivamentoRecurso();
    this.validators.get('email')!.disable();
    this.validators.get('telefone')!.disable();
  }

  public async arquivarRecurso (): Promise<void> {
    const recurso = {
      recursoId: this.recursoSelecionado.recursoId,
      tipoRecurso: this.validators.get('tipoProcesso')?.value,
      nomeInteressado: this.validators.get('nomeInteressado')?.value,
      motivoId: this.validators.get('motivoArq')?.value,
    }

    try {
      await this.recursoService.postArquivarRecurso(recurso);
      this.toastr.success('Recurso arquivado.')
      await this.obterListaAtualizadaRecurso();
    } catch(error) {
      this.toastr.error('Error ao arquivar o recurso.')
    }
  }

  public async confirmarRecurso (): Promise<void> {
    const recurso = {
      recursoId: this.recursoSelecionado.recursoId,
      tipoRecurso: this.validators.get('tipoProcesso')?.value,
      nomeInteressado: this.validators.get('nomeInteressado')?.value
    }

    try{
      await this.recursoService.postAceitarRecurso(recurso);
      this.toastr.success('Recurso confirmado.')
      await this.obterListaAtualizadaRecurso();
    }catch(error) {
      this.toastr.error('Error ao confirmar o recurso.')
    }
  }

  public disableBtnAceite(): boolean {
    return this.validators.valid
  }

  public receberValueCheckboxFile(checkboxValue: any) : void {
    this.limparImagens();
     this.checkboxsFile.forEach(check => {
      check.value === checkboxValue ?  check.isCheck = !check.isCheck : ''

      const checkboxIsTrue = this.checkboxsFile.filter(x => x.isCheck == true)
      this.mostrarDoisArquivos = checkboxIsTrue.length > 1;

      setTimeout(() => {
        check.isCheck === true ?
        this.mostrarImagemAmpliada(
          new Documento(check.value.base64, check.value.typeDocumento, check.value.tipoImagem))
          :
        this.removerImagemAmpliada(
          new Documento(check.value.base64, check.value.typeDocumento, check.value.tipoImagem));
      }, 500)
    });
  }

  private async definirRecursoSelecionada() : Promise<void> {
    this.recursos = this.recursos.filter( x => x.statusProcesso !== 2 )
    await this.fecharLote();
    this.recursoSelecionado = this.recursos[0];
  }

  private popularFormulario(): void {
    this.validators.patchValue({
      nomeInteressado: this.recursoSelecionado.nomeInteressado,
      telefone: this.recursoSelecionado.telefone,
      email: this.recursoSelecionado.email,
      tipoProcesso: this.recursoSelecionado.tipoRecurso
    })
  }

  private async obterImagens(): Promise<void> {
    this.checkboxsFile = []
    await this.obterImagemDocumento(this.recursoSelecionado.recursoId,
      this.tipoImagem.FormularioIndicacao).then( resp => {
        if(resp) {
          this.checkboxsFile.push({name: this.tipoImagem.FormularioIndicacao.toString(), isCheck: false, value: resp});
        }
      })
    await this.obterImagemDocumento(this.recursoSelecionado.recursoId,
      this.tipoImagem.DocResponsabilidade).then(resp => {
        if(resp) {
          this.checkboxsFile.push({name: this.tipoImagem.DocResponsabilidade.toString(), isCheck: false, value: resp});
        }
      });
    await this.obterImagemDocumento(this.recursoSelecionado.recursoId,
      this.tipoImagem.DocumentoIdentificador).then(resp => {
        if(resp) {
          this.checkboxsFile.push({name: this.tipoImagem.DocumentoIdentificador.toString(), isCheck: false, value: resp});
        }
      });
    await this.obterImagemDocumento(this.recursoSelecionado.recursoId,
      this.tipoImagem.DocNotificacaoOuPenalidade).then(resp => {
        if(resp) {
          this.checkboxsFile.push({name: this.tipoImagem.DocNotificacaoOuPenalidade.toString(), isCheck: false, value: resp});
        }
      });
    await this.obterImagemDocumento(this.recursoSelecionado.recursoId,
      this.tipoImagem.DocComprobatorio).then(resp => {
        if(resp) {
          this.checkboxsFile.push({name: this.tipoImagem.DocComprobatorio.toString(), isCheck: false, value: resp});
        }
      });
      this.carregouImagens = true;
  }


  private async obterImagemDocumento(recursoId: number, type: TipoImagem): Promise<any> {
    try{
      debugger
      const response = await this.recursoService.getImagenRecurso(recursoId, type);
      const imagemDocumentoType = response.headers.get('Content-Type');
      let imagemDocumentoBase64 = Buffer.from(response.body, 'binary').toString('base64');
      if(imagemDocumentoType != 'application/pdf'){
        imagemDocumentoBase64 = `data:image/png;base64,${imagemDocumentoBase64}`
      }
      return new Documento(imagemDocumentoBase64, imagemDocumentoType, type)
    } catch(error) {
    }
  }


  public RotacionarImagem(imagemId: string) : void {
    const containerImagem = document.getElementById(imagemId);
    const divElementImagem = containerImagem?.getElementsByTagName('div');
    const img = divElementImagem?.item(0);
    this.positionImagem = this.positionImagem + 90;
    img!.style.transform = `rotate(${this.positionImagem}deg)`;
  }

  private async obterMotivoArquivamentoRecurso(): Promise<void> {
    try {
      const responseApi = await this.motivoArquivamentoService.getMotivoArquivamentoRecurso();
      responseApi.listaMotivosArquivamento.forEach((x: any) => {
        const motivo = new MotivoArquivamento(x)
        this.motivosArquivamento.push(motivo)
      })
    }catch(error){
      this.toastr.error('Error ao obter os motivos de arquivamento do recurso')
    }
  }

  private async obterListaAtualizadaRecurso(): Promise<void> {
    try{
      this.recursos = [];
      const responseApi = await this.recursoService.getRecursos(this.loteId);
      responseApi.listaRecursos.forEach((x: any) => {
        const lote = new Recurso(x)
        this.recursos.push(lote)
      })
      await this.definirRecursoSelecionada();
      this.limparImagens();
      this.popularFormulario();
      await this.obterImagens();

    }catch(error){
      this.toastr.error('Error ao obter a lista de recursos do lote')
    }
  }

  private limparImagens(): void{
    this.mostrarPdf2 = false;
    this.mostrarImagem2 = false;
    this.imagem2 = "";

    this.mostrarPdf3 = false;
    this.mostrarImagem3 = false;
    this.imagem3 = "";

    this.mostrarPdf1 = false;
    this.mostrarImagem1 = false;
    this.imagem1 = "";

    this.mostrarPdf4 = false;
    this.mostrarImagem4 = false;
    this.imagem4 = "";

    this.mostrarPdf5 = false;
    this.mostrarImagem5 = false;
    this.imagem5 = "";
  }


  private async fecharLote(): Promise<void> {
    if(this.recursos.length === 0 ){
      try {
        await this.recursoService.fecharLote(this.loteId);
        this.toastr.success('Lote fechado.')
        this.route.navigate(['/recurso'])
      } catch(error) {
        this.toastr.error('Error ao fechar o lote.')
      }
    }
  }

  public removerImagemAmpliada(arquivoVisualizacao: Documento): void {
    if (arquivoVisualizacao.tipoImagem === 1) {
      this.mostrarPdf1 = false;
      this.mostrarImagem1 = false;
      this.imagem1 = '';
      return
    }
    if (arquivoVisualizacao.tipoImagem === 2) {
      this.mostrarPdf2 = false;
      this.mostrarImagem2 = false;
      this.imagem2 = '';
      return
    }
    if (arquivoVisualizacao.tipoImagem === 3) {
      this.mostrarPdf3 = false;
      this.mostrarImagem3 = false;
      this.imagem3 = '';
      return
    }
    if (arquivoVisualizacao.tipoImagem === 4) {
      this.mostrarPdf4 = false;
      this.mostrarImagem4 = false;
      this.imagem4 = '';
      return
    }
    if (arquivoVisualizacao.tipoImagem === 5) {
      this.mostrarPdf5 = false;
      this.mostrarImagem5 = false;
      this.imagem5 = '';
      return
    }
  }

  public mostrarImagemAmpliada(arquivoVisualizacao: Documento): void {
    if(arquivoVisualizacao.typeDocumento === "application/pdf") {
      if( arquivoVisualizacao.tipoImagem === 1){
        this.mostrarPdf1 = true;
        this.mostrarImagem1 = false;
        this.imagem1 = arquivoVisualizacao.base64;
      }
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = true;
        this.mostrarImagem2 = false;
        this.imagem2 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 3) {
        this.mostrarPdf3 = true;
        this.mostrarImagem3 = false;
        this.imagem3 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 4) {
        this.mostrarPdf4 = true;
        this.mostrarImagem4 = false;
        this.imagem4 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 5) {
        this.mostrarPdf5 = true;
        this.mostrarImagem5 = false;
        this.imagem5 = arquivoVisualizacao.base64;
      }
    }
    else {
      if( arquivoVisualizacao.tipoImagem === 1){
        this.mostrarPdf1 = false;
        this.mostrarImagem1 = true;
        this.imagem1 = arquivoVisualizacao.base64;
      }
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = false;
        this.mostrarImagem2 = true;
        this.imagem2 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 3) {
        this.mostrarPdf3 = false;
        this.mostrarImagem3 = true;
        this.imagem3 = arquivoVisualizacao.base64;
      }
      if( arquivoVisualizacao.tipoImagem === 4){
        this.mostrarPdf4 = false;
        this.mostrarImagem4 = true;
        this.imagem4 = arquivoVisualizacao.base64;
      }
      if( arquivoVisualizacao.tipoImagem === 5){
        this.mostrarPdf5 = false;
        this.mostrarImagem5 = true;
        this.imagem5 = arquivoVisualizacao.base64;
      }
    }
  }

  private getEnumTipeDocumento(tipoDocumento: number): string {
    if(tipoDocumento == 1)
      return 'Documento identificador'

    if(tipoDocumento == 2)
      return 'Documento de Notificação ou Penalidade'

    if(tipoDocumento == 3)
      return 'Formulário Indicação'

    if(tipoDocumento == 4)
      return 'Documento de responsabilidade'

    if(tipoDocumento == 5)
      return 'Documento Comprobatório'

    return ''
  }

}
