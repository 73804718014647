import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MultiCheckbox } from '../../interfaces/multi-checkbox';

@Component({
  selector: 'app-multi-checkbox-file',
  templateUrl: './multi-checkbox-file.component.html',
  styleUrls: ['./multi-checkbox-file.component.scss']
})
export class MultiCheckboxFileComponent implements OnChanges {

  public ngOnChanges(changes: SimpleChanges): void {
    setTimeout(async () => {
      const fistDocumete = this.checkboxs[0];
      this.emitEventCheckboxFile(fistDocumete);
     }, 500);
  }

  @Input() 
  public checkboxs: MultiCheckbox[]

  @Output()
  public checkboxSelectFileEmit = new EventEmitter<any>();

  public emitEventCheckboxFile(checkbox: MultiCheckbox) {
    this.checkboxSelectFileEmit.emit(checkbox.value);
  }

  public desabilitarInput(): boolean {
    const checkboxIsTrue = this.checkboxs.filter(check => check.isCheck)
    return checkboxIsTrue.length >= 2
  }
}
