import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoteRecurso } from 'src/app/features/recursos/models/lote-recurso';
import { Recurso } from 'src/app/features/recursos/models/recurso';
import { RecursoService } from 'src/app/features/recursos/service/recurso.service';

@Component({
  selector: 'app-processo-validacao-recurso',
  templateUrl: './processo-validacao-recurso.component.html',
  styleUrls: ['./processo-validacao-recurso.component.scss']
})
export class ProcessoValidacaoRecursoComponent implements OnInit {

  public loteId: number;
  public loteSelecionado: Recurso[] = []
  public carregouLote: boolean;

  constructor(private route: ActivatedRoute, 
    private recursoService: RecursoService,
    private toastr: ToastrService) { }

  public async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (objeto: any) => {
      this.loteId = objeto.id;
      await this.obterRecursos(objeto.id);
    })
  }

  public async desvincularLoteAoUsuario(): Promise<void> {
    await this.recursoService.desvincularLoteAoUsuario(this.loteId);
  }


  private async obterRecursos(loteId: number): Promise<void> {
    try{
      const responseApi = await this.recursoService.getRecursos(loteId);
      responseApi.listaRecursos.forEach((x: any) => {
        const lote = new Recurso(x)
        this.loteSelecionado.push(lote);
      })
      this.carregouLote = true;

    }catch(error){
      this.toastr.error('Error ao obter a lista de recursos do lote.')
    }
  }
}
