<div class="container-home">
    <section class="container-blue">
        <div class="container d-flex">
            <div>
                <h2>
                    Controle de Processos
                    <i class="bi bi-box-arrow-in-down"></i>
                </h2>
                <hr size="10" width="40%" align="left" class="linha-horizontal">
                <h5>
                  Controle de processos cadastrados e validados.
                </h5>
                <p>As alterações nos processos são permitidos antes da transmissão, desde que o lote já esteja fechado.</p>
                <p>
                  <i class="bi bi-chevron-right"></i>
                  Processos
                  <i class="bi bi-chevron-right"></i>
                  Controle de Processos
              </p>

            </div>
        </div>
    </section>
    <section class="modal-lotes containers">
        <div class="header-modal d-flex">
            <form [formGroup]="validators" class="form-recurso" (ngSubmit)="onSubmit()">
                <div class="form-group mb-3 titulo-campos-pesquisa dt-inicial">
                  <label for="dataInicial" class="titulo-labels-pesquisa">Data Inicial</label>
                  <input type="text"
                        id="dataInicial"
                        placeholder="Data Inicial"
                        class="form-control input-date"
                        bsDatepicker
                        formControlName="dataInicial"
                        (ngModelChange)="onSubmit()"
                        [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}">
                </div>
                <div class="form-group mb-3 titulo-campos-pesquisa">
                  <label class="titulo-labels-pesquisa">Data Final</label>
                  <input type="text"
                        id="dataFinal"
                        placeholder="Data Final"
                        class="form-control input-date"
                        bsDatepicker
                        formControlName="dataFinal"
                        (ngModelChange)="onSubmit()"
                        [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}">
                </div>
                
                <div class="form-check-change form-check form-group mb-3 p-2 titulo-campos-pesquisa">
                  <label for="statusTransmissao" class="titulo-labels-pesquisa ">Status Transmissão</label>
                  <select class="form-select input-select" formControlName="statusTransmissao" id="statusTransmissao" (ngModelChange)="onSubmit()">
                      <option value="todos">Todos</option>
                      <option value="transmitidas">Transmitidas</option>
                      <option value="transmitir">Transmitir</option>
                  </select>
                </div>
                <div class="form-check-change form-check form-group mb-3 p-2 titulo-campos-pesquisa">
                  <label class="titulo-labels-pesquisa">Pesquisar</label>
                  <input type="text" placeholder="Ait, Placa ou Protocolo" class="form-control" formControlName="termo"  (ngModelChange)="onSubmit()">
                </div>
                <div class="form-check-change form-check form-group mb-3 p-2 Qtd titulo-campos-pesquisa">
                  <label for="take" class="titulo-labels-pesquisa">Qtd</label>
                    <select class="form-select quantity" formControlName="take" id="take" (ngModelChange)="onSubmit()">
                        <option  *ngFor="let take of takeFilter" [ngValue]="take">{{take}}</option>
                    </select>
                </div>
            </form>
        </div>
        <div class="container-lotes d-flex">
            <table class="table">
                <thead>
                  <tr>
                    <th>LOTE</th>
                    <th>TIPO DE PROCESSO</th>
                    <th>NUMERO DO PROTOCOLO</th>
                    <th>DATA PROCESSO</th>
                    <th>INTERESSADO</th>
                    <th>AIT</th>
                    <th>PLACA</th>
                    <th>DATA ANÁLISE</th>
                    <th>MOTIVO ARQUIVAMENTO</th>
                    <th>DATA TRANSMITIDA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let recurso of recursos | paginate: { itemsPerPage: take, currentPage: page }">
                    <td>{{recurso.lote}}</td>
                    <td>{{retornarTipoDeProcesso(recurso.tipoRecurso)}}</td>
                    <td>{{recurso.nrProtocolo}}</td>
                    <td>{{recurso.dataProtocolo | date : 'dd/MM/yyyy' }}</td>
                    <td>{{recurso.nomeInteressado}}</td>
                    <td>{{recurso.autoInfracao}}</td>
                    <td>{{recurso.placa}}</td>
                    <td>{{recurso.dataAnalise | date : 'dd/MM/yyyy' }}</td>
                    <td>{{recurso.motivoArquivamento}}</td>
                    <td>{{recurso.dataTransmissao | date : 'dd/MM/yyyy'}}</td>

                    <td><button class="btn-arquivado"><i class="bi bi-eye" (click)="openDetalhamentoRecurso(recurso)"></i></button><td>
                    <td *ngIf="!recurso.dataTransmissao"><button class="btn-arquivado"><i class="bi bi-pencil-square" (click)="openEdicaoRecurso(recurso)"></i></button><td></td>
                  </tr>
                </tbody>
              </table>
              <div class="footer-table">
                <pagination-controls nextLabel="Próximo" previousLabel="Anterior" (pageChange)="page = $event"></pagination-controls>
              </div>
        </div>
    </section>

</div>

