export class LotesIndicacao {
    nomeLote: string;
    loteID: number;
    dataAbertura: Date;
    qtdRegistrosFeitos: number;
    qtdRegistrosLote: number;
    usuarioVinculado: number;
    dataHoraUsuarioVinculado: Date;
    dataAnalise: Date;
    nomeUsuarioVinculado: string;

    constructor(response: any){
            this.nomeLote = response.nomeLote;
            this.loteID = response.loteID,
            this.dataAbertura = response.dataAbertura,
            this.qtdRegistrosFeitos = response.qtdRegistrosFeitos,
            this.qtdRegistrosLote = response.qtdRegistrosLote,
            this.usuarioVinculado = response.usuarioVinculado,
            this.dataHoraUsuarioVinculado = response.dataVinculoUsuario,
            this.dataAnalise = response.dataAnalise,
            this.nomeUsuarioVinculado = response.nomeUsuarioVinculado
        }
}
