export const environment = {
  production: true,
  featureRecurso: true,
  possui: false,
  api: 'https://processossemuttran.homologacao.promultonline.com.br',
  urlImpressao: "http://impressao.homologacao.promultonline.com.br/",
  urlProcessamento: "http://processamento.homologacao.promultonline.com.br/Processamento/PrincipalProcessamento.aspx",
  auth: {
    issuer: 'https://identity.auth.promultonline.com.br',
    clientId: 'processosadmpiracicaba',
    postLogoutRedirectUri: 'https://processossemuttran.homologacao.promultonline.com.br',
    redirectUri: 'https://processossemuttran.homologacao.promultonline.com.br/signin-oidc',
    scope:"openid profile email processosadmpiracicaba management",
    oidc: true,
  }
};
