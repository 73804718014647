import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { LocalStorageKeys } from './core/enums/local-storage-keys';
import { LocalStorageService } from './core/services/local-storage.service';
import { UserService } from './features/user/service/user.service';
import { MenuComponent } from './shared/menu/menu.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public nomeUsuario: string;

  constructor(private localStorageService: LocalStorageService,
    private userService: UserService,
    private oAuthService: OAuthService,
    private toastr: ToastrService) {
    this.limparDadosDeCacheAntigos();
    Promise.resolve(this.configureWithNewConfigApi());
  }
  
  private async configureWithNewConfigApi() {
    this.oAuthService.configure(environment.auth);
    this.oAuthService.setStorage(localStorage);
    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();

    await this.oAuthService.loadDiscoveryDocumentAndTryLogin().then();
    if (this.oAuthService.hasValidIdToken() || this.oAuthService.hasValidAccessToken()) {
      try{
        this.nomeUsuario =  await this.obterNomeUsuario()
      } catch(error) {
        this.toastr.error('Error ao obter nome do usuário.')
      }
    }
    this.oAuthService.setupAutomaticSilentRefresh();
  }


  private limparDadosDeCacheAntigos(): void {
    const tokenAntigoSitema = this.localStorageService.get(LocalStorageKeys.tokenAntigoSistema);
    if(tokenAntigoSitema != null) {
      this.localStorageService.clear();
      setTimeout(() => {
        window.location.reload();
      }, 750)
    }
  }

  private async obterNomeUsuario(): Promise<string> {
    const userNameLocalStorage = this.localStorageService.get(LocalStorageKeys.userName);
    if(userNameLocalStorage) {
      return userNameLocalStorage
    }
    const user = await this.userService.getUsuario();
    return user.nomeUsuario;
  }
}
