<nav class="navbar navbar-expand-lg container-navbar">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link icon-vector" [routerLink]="['/home']">
              <svg-icon src="../../../assets/icon/paper.svg"></svg-icon>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/indicacao']">Indicação</a>
          </li>
          <li class="nav-item" *ngIf="featureRecurso">
            <a class="nav-link" [routerLink]="['/recurso']">Processos</a>
          </li>
        </ul>
        <ul class="navbar-nav logout">
          <li class="nav-item dropdown nav-icon">
            <a class="nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <svg-icon src="../../../assets/icon/menu-other-systems.svg"></svg-icon>
            </a>
            <ul class="dropdown-menu dropdown-sistemas " aria-labelledby="navbarDropdownMenuLink">
              <li class="header">
                <p>Outros sistemas</p>
                <hr size="10" width="100%" align="left" class="linha-horizontal">
              </li>
              <li>
                <button class="dropdown-item btn-dropdown">
                  Processamento
                  <i class="bi bi-link-45deg"></i>
                </button>
              </li>
              <li *ngIf="exist">
                <button class="dropdown-item btn-dropdown">
                  Impressão
                  <i class="bi bi-link-45deg icon-button-link"></i>
                </button>
              </li>
            </ul>
          </li>
          <li class="nav-item container-avata">
            <button class="nav-link btn-nome-usuario" (click)="login()">
              <svg-icon class="icon-avatar" src="../../../assets/icon/avatar.svg"></svg-icon>
              Faça seu login
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
