import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LocalStorageKeys } from 'src/app/core/enums/local-storage-keys';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LoteRecurso } from 'src/app/features/recursos/models/lote-recurso';

@Component({
  selector: 'app-modal-recurso-lotes',
  templateUrl: './modal-recurso-lotes.component.html',
  styleUrls: ['./modal-recurso-lotes.component.scss']
})
export class ModalRecursoLotesComponent implements OnChanges {

  @Input()
  public lotesRecurso: LoteRecurso[];

  @Output()
  public loteIdSelecionado = new EventEmitter<number>();

  @Output()
  public recarregarLotes = new EventEmitter<any>();

  public loteID: string;

  public lotes: LoteRecurso[];

  constructor(private localStorageService: LocalStorageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.lotes = this.lotesRecurso;
  }

  public search() {
    if (this.loteID === '') {
      this.lotes = this.lotesRecurso;
      return;
    }
    this.lotes = this.lotesRecurso.filter((response) => {
      return String(response.loteId).includes(this.loteID)
    });
  }
  public selectLote(lote: LoteRecurso): void {
    this.loteIdSelecionado.emit(lote.loteId);
  }

  public atualizarLotes(): void {
    this.recarregarLotes.emit();
  }

  
  public loteEmAndamento(lote: LoteRecurso): boolean {
    const usuarioId = parseInt(this.localStorageService.get(LocalStorageKeys.userId));
    const temDataHoraVinculado = lote.dataHoraUsuarioVinculado
    if(temDataHoraVinculado) {
      let dataUsuarioFoiVinculado = new Date(lote.dataHoraUsuarioVinculado)
      const dataMaximaDoVinculo = dataUsuarioFoiVinculado.setHours(dataUsuarioFoiVinculado.getHours() + 1);
      var dataMaximaUsuarioVinculado = new Date(dataMaximaDoVinculo);
      const dateNow = new Date();

      if(dateNow > dataMaximaUsuarioVinculado)
        return true;
    }

    if(lote.usuarioVinculado === usuarioId || lote.usuarioVinculado == null){
      return true;
    }
    
    return false;
  }


}
