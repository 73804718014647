<div class="formRedefinirsenha">
  <section class="container-blue">
      <div class="container d-flex">
      </div>
  </section>
  <main>
      <form [formGroup]="senhaForm" class="quadrado" (ngSubmit)="verificarHabilitado()">
          <div class="passwords">
              <h1 class="Title">Alterar a Senha</h1>
              <div class="LinhaHR"></div>
              <div class="CampoAtual">
                  <h2 class="SenhaAtualtxt">Senha Atual<span>*</span></h2>
                  <input class="formGroup" type="password" formControlName="senhaatual" id="senhaatual">
              </div>
              <div class="CampoNova">
                  <h2 class="NovaSenhatxt">Nova Senha<span>*</span></h2>
                  <input class="NovaSenha" type="password" formControlName="senhanova" id="senhanova" minlength="6">
                  <div *ngIf="senhanovaControl?.hasError('minlength')" class="text-danger">
                    <p class="message">A senha deve ter no mínimo 6 caracteres.</p>
                  </div>
              </div>
              <div class="CampoConfirmar">
                  <h5 class="ConfirmarSenhatxt">Confirmar Senha<span>*</span></h5>
                  <input class="ConfirmarSenha" type="password" formControlName="senhaconfirm" id="senhaconfirm" minlength="6">
                  <div *ngIf="senhanovaControl?.dirty && senhaconfirmControl?.dirty && !verificarSenha()"
                  class="text-danger">
                  <p class="message">As senhas não são iguais.</p>
              </div>
              </div>
              <button class="btnConfirma" name="Confirmar" id="confirm" [disabled]="!senhaForm.valid || !verificarSenha()" type="submit">
                  Confirmar
              </button>
          </div>
      </form>
  </main>
  <footer class="empresa-desenvolvedora">
      Desenvolvido com
      <svg-icon class="icon-coracao" src="../../../assets/icon/coracao.svg"></svg-icon>
      por<b>Serget</b>
  </footer>
</div>
