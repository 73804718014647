import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/features/user/service/user.service';
import { HttpErrorResponse  } from '@angular/common/http';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.scss'],
})
export class RedefinirSenhaComponent implements OnInit {
  senhaForm: FormGroup;
  senhanovaControl: AbstractControl | null;
  senhaconfirmControl: AbstractControl | null;
  senhasIguais: boolean = true;

  constructor(private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService, private userService: UserService) {}

  ngOnInit(): void {
    this.senhaForm = this.formBuilder.group({
      senhaatual: ['', Validators.required],
      senhanova: ['', [Validators.required, Validators.minLength(6)]],
      senhaconfirm: ['', Validators.required],

    });

    this.senhanovaControl = this.senhaForm.get('senhanova');
    this.senhaconfirmControl = this.senhaForm.get('senhaconfirm');
  }

  async verificarHabilitado() {
    if (this.senhaForm.valid && this.verificarSenha()) {
      const senhanovaa = this.senhaForm.get('senhanova');
      const senhaconfirmb = this.senhaForm.get('senhaconfirm');

      if (senhanovaa && senhaconfirmb) {
        const senhanova = senhanovaa.value;
        const senhaconfirm = senhaconfirmb.value;
        if (senhanova === senhaconfirm) {
          try {
            let obj = {
              senhaAtual: this.senhaForm.get('senhaatual')?.value,
              novaSenha: senhanova,
              confirmaSenha: senhaconfirm
            }

            let resp = await this.userService.alterarSenha(obj)
            this.toastr.success(`${resp}`);

          }catch(error) {
            let errorMessage = 'Erro desconhecido!';
            if (error instanceof HttpErrorResponse) {
              errorMessage = error.error;
            } else if (error instanceof ErrorEvent) {
              errorMessage = `Erro: ${error.message}`;
            } else if (typeof error === 'string') {
              errorMessage = error;
            }

            this.toastr.error(errorMessage)
          }

        } else {
          this.toastr.error('ERRO, as senhas não são iguais');
        }
      } else {
        this.toastr.info('Preencha todos os campos corretamente antes de prosseguir.');
      }
    }
  }
  verificarSenha() {
    const senhanovaa = this.senhaForm.get('senhanova');
    const senhaconfirmb = this.senhaForm.get('senhaconfirm');
    if (senhanovaa && senhaconfirmb) {
      const senhanova = senhanovaa.value;
      const senhaconfirm = senhaconfirmb.value;
      if (senhanova === senhaconfirm) {
        return true;

      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
