import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";
import { BaseService } from "src/app/core/services/base.service";
import { HttpService } from "src/app/core/services/http.service";
import { TipoImagem } from "src/app/shared/enums/TipoImagem";


@Injectable({
    providedIn: 'root',
})

export class IndicacaoService extends BaseService {

    constructor(oAuthService: OAuthService,
        private httpService: HttpService){
        super(oAuthService)
    }


    public async getProcessosDeValidacao(loteId: number): Promise<any> {

        const params = new HttpParams().set('loteId', loteId);

        const url = `${this.baseUrl()}/api/indicacao/vinculados`;

        const response = await this.httpService.get(url, {headers: this.getHeaders(), params});

        return this.extractData(response)
    }

    public async getIndicacao(indicacaoId: number): Promise<any> {
        const params = new HttpParams().set('indicacaoId', indicacaoId);

        const url = `${this.baseUrl()}/api/indicacao/Id`;

        const response = await this.httpService.get(url, {headers: this.getHeaders(), params});

        return this.extractData(response)
    }

    public async getImagensDeIndicacao(indicacaoId: number, tipoImagem: TipoImagem): Promise<any> {
        const response = await this.httpService.getImg(`${this.baseUrl()}/api/indicacao/${indicacaoId}/imagem/${tipoImagem}`,{headers: this.getHeaders(), responseType: 'arraybuffer', observe: 'response' })
        return this.extractData(response)
    }

    public async postAceitarIndicacaoCondutor(indicacao: any): Promise<void> {
        const response = await this.httpService.post(`${this.baseUrl()}/api/indicacao/aceitar`, indicacao, this.getHeaderJson());
        return this.extractData(response)
    }

    public async postArquivarIndicacaoCondutor(indicacao: any) : Promise<void> {
        const response = await this.httpService.post(`${this.baseUrl()}/api/indicacao/arquivar`, indicacao, this.getHeaderJson());
        return this.extractData(response)
    }

    public async postAtualizarIndicacaoCondutor(indicacao: any) : Promise<void> {
        const response = await this.httpService.post(`${this.baseUrl()}/api/indicacao/Atualizar`, indicacao, this.getHeaderJson());
        return this.extractData(response)
    }

    public async getIndicacoesArquivadas(dataInicial: string, dataFinal: string, termo: string, skip: number, take: number, statusTransmissao: string, statusIndicacao: string): Promise<any> {
      // , tipoProcesso: number
        const params = new HttpParams()
            .set('dataInicial', dataInicial)
            .set('dataFinal', dataFinal)
            .set('termo', termo)
            .set('skip', skip)
            .set('take', take)
            .set('statusTransmissao', statusTransmissao)
            .set('statusIndicacao', statusIndicacao);
            // .set('tipoProcesso', tipoProcesso);

        const url = `${this.baseUrl()}/api/indicacao/arquivadas`;

        const response = await this.httpService.get(url, {headers: this.getHeaders(), params});

        return this.extractData(response);
    }



}
