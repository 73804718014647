import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { UserService } from 'src/app/features/user/service/user.service';
import { LocalStorageService } from '../services/local-storage.service';
import { LocalStorageKeys } from '../enums/local-storage-keys';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
    template: ''
})
export class LoginCallbackComponent implements OnInit {

    private usuarioLogado: boolean;

    constructor(private oauthService: OAuthService, 
        private router: Router, 
        private userService: UserService,
        private localStorageService: LocalStorageService,
        private toastr: ToastrService) {
    }

    public async ngOnInit() {

        
        await this.oauthService.loadDiscoveryDocument().then(() => {
            this.usuarioLogado = true;
        });

        if(this.usuarioLogado){
            try {
                await this.setUsuarioLocalStorage();
                await this.userService.postVerificarUsuario();
                this.router.navigate(["/"])
            }catch(Error) {
                this.toastr.error('Usuario não possui permissão para acessar este sistema. Você sera redirecionado para página de logoff em 5 segundos');
                const token = this.localStorageService.get(LocalStorageKeys.id_token);
                this.localStorageService.clear();
                setTimeout(() => {
                    window.location.href = `${environment.auth.issuer}/connect/endsession?id_token_hint=${token}` +
                    `&post_logout_redirect_uri=${environment.auth.postLogoutRedirectUri}`
                }, 5000)
            }
        }
    }

    private async setUsuarioLocalStorage(): Promise<void> {
        const user = await this.userService.getUsuario();
        this.localStorageService.set(LocalStorageKeys.userName, user.nomeUsuario);
        this.localStorageService.set(LocalStorageKeys.userEmail, user.email);
        this.localStorageService.set(LocalStorageKeys.userId, user.id);
        this.localStorageService.set(LocalStorageKeys.userSub, user.sub);
    }
}
