import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { LocalStorageKeys } from "src/app/core/enums/local-storage-keys";
import { BaseService } from "src/app/core/services/base.service";
import { HttpService } from "src/app/core/services/http.service";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { User } from "../models/user";

@Injectable({
    providedIn: 'root',
})
  
export class UserService extends BaseService {

    constructor(oAuthService: OAuthService,
        private httpService: HttpService,
        private localStorageService: LocalStorageService){
        super(oAuthService)
    }

    public async getUsuario() : Promise<User> {
        const response = await this.httpService.get(`${this.baseUrl()}/api/user`, this.getHeaderJson())
        return this.extractData(response)
    }

    public async postVerificarUsuario(): Promise<void> {
        const response = await this.httpService.post(`${this.baseUrl()}/api/user/VerificarUsuario`, this.getHeaderJson())
        return this.extractData(response)
    }

    public async postSignout(): Promise<void> {
        const response = await this.httpService.post(`${this.baseUrl()}/api/user/Signout`,{idToken: this.localStorageService.get(LocalStorageKeys.userSub)},  this.getHeaderJson())
        return this.extractData(response)
    }

    public async obterUsuarioPorId(usuarioId: number) : Promise<User> {
        const params =  new HttpParams().set('usuarioId', usuarioId);
        const response = await this.httpService.get(`${this.baseUrl()}/api/user/BuscarUsuario`,  {headers: this.getHeaders(), params: params})
        return this.extractData(response)
    }

    public async alterarSenha(obj: any) : Promise<string> {
        const response = await this.httpService.post(`${this.baseUrl()}/api/user/redefinir/senha`, obj,  this.getHeaderJson())
        return this.extractData(response)
    }
}