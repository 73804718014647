export class Recurso {
    nomeInteressado: string;
    recursoId: number;
    statusProcesso: number;
    tipoRecurso: string;
    autoInfracao: string;
    placa: string;
    dataProtocolo: Date;
    dataArquivamento: Date;
    nrProtocolo: string;
    telefone: number;
    email: string;


    constructor(response: any){
    this.nomeInteressado = response.nomeInteressado;
    this.recursoId = response.recursoID;
    this.statusProcesso = response.statusProcesso;
    this.tipoRecurso = response.tipoRecurso;
    this.nrProtocolo = response.numeroProtocolo;
    this.dataProtocolo = response.dataProtocolo;
    this.dataArquivamento = response.dataAnalise;
    this.placa = response.placa,
    this.autoInfracao = response.autoInfracao;
    this.telefone = response.celularInteressado;
    this.email = response.emailInteressado
    }
}
