import { TipoImagem } from "../enums/TipoImagem"


export class Documento {
    typeDocumento: string
    base64: string
    tipoImagem: TipoImagem

    constructor(base64: string, typeDocumento: string, tipoImagem: TipoImagem){
        this.typeDocumento = typeDocumento,
        this.base64 = base64
        this.tipoImagem = tipoImagem
    }
}