import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoteRecurso } from 'src/app/features/recursos/models/lote-recurso';
import { RecursoService } from 'src/app/features/recursos/service/recurso.service';

@Component({
  selector: 'app-recursos',
  templateUrl: './recursos.component.html',
  styleUrls: ['./recursos.component.scss']
})
export class RecursosComponent implements OnInit {

  public lotes: LoteRecurso[] = [];
  private interval: any;
  
  constructor(private route: Router, 
    private recursoService: RecursoService,
    private toastr: ToastrService) { 
  }


  public async ngOnInit(): Promise<void> {
    await this.obterLotes();
    this.interval = setInterval(async () => {
      await this.recarregarLotes('teste');
     }, 30000);
  }

  public ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  public async receberLoteSelecionado(loteId: number) : Promise<void> {
    try {
      await this.recursoService.vincularLoteAoUsuario(loteId);
      this.route.navigate(['/processo-de-validacao-recurso', loteId]);
      clearInterval(this.interval);
    } catch(error) {
      this.toastr.error('Error ao vincular o usuário ao lote.')
    }
  }
  
  public async recarregarLotes(event: any) {
    this.lotes = [];
    await this.obterLotes();
  }

  private async obterLotes(): Promise<void> {
    try{
      const response = await this.recursoService.getLotesRecurso();
      response.listaTodosDadosLotesEmAberto.forEach((x: any) => {
        const lote = new LoteRecurso(x)
        this.lotes.push(lote)
      })
    }catch(error: any){
      const mensagemError = error.error[0].message;
      if(mensagemError == 'Não foram encontrado lotes em aberto.')
      {
        this.toastr.info(mensagemError)
      } else{
        this.toastr.error(mensagemError)
      }
    }
  }

}
