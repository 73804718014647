import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LotesIndicacao } from 'src/app/features/indicacao-condutor/interfaces/lotes';
import { LoteService } from 'src/app/features/indicacao-condutor/services/lote.service';

@Component({
  selector: 'app-indicacao-condutor',
  templateUrl: './indicacao-condutor.component.html',
  styleUrls: ['./indicacao-condutor.component.scss']
})
export class IndicacaoCondutorComponent implements OnInit {

  public lotes: LotesIndicacao[] = [];
  public interval: any;

  constructor(private route: Router, 
    private loteService: LoteService,
    private toastr: ToastrService,
    private router: Router) { 
  }

  public async ngOnInit(): Promise<void> {
    await this.obterLotes();
    this.interval = setInterval(async () => {
      await this.recarregarLotes('teste');
     }, 30000);
  }

  public ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  public async receberLoteSelecionado(loteId: number) : Promise<void> {
    try {
      await this.loteService.vincularLoteAoUsuario(loteId);
      this.route.navigate(['/processo-de-validacao', loteId]);
      clearInterval(this.interval);
    }catch(error) {
      this.toastr.error('Error ao vincular o usuário ao lote.');
    }
  }
  
  public async recarregarLotes(event: any) {
    this.lotes = [];
    await this.obterLotes();
  }

  private async obterLotes(): Promise<void> {
    try{
      const response = await this.loteService.getLotes();
      response.listaTodosDadosLotesEmAberto.forEach((x: any) => {
        const lote = new LotesIndicacao(x);
        this.lotes.push(lote);
      })
    }catch(error: any) {
      const mensagemError = error.error[0].message;
      if(mensagemError == 'Não foram encontrado lotes em aberto.')
      {
        this.toastr.info(mensagemError)
      } else{
        this.toastr.error(mensagemError)
      }
    }
  }
}
