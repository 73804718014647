<section class="modal-lotes containers">
    <div class="header-modal d-flex">
        <h6><i class="bi bi-archive"></i>Lotes</h6>
        <div class=" d-flex">
            <div class="input-search d-flex">
                <input type="text" placeholder="Pesquisar por lote" class="form-control" [(ngModel)]="loteID" (input)="search()">
            </div>
            <button (click)="atualizarLotes()"><i class="bi bi-arrow-clockwise"></i></button>
        </div>
    </div>
    <div class="container-lotes d-flex">
        <div class="lotes d-flex" *ngFor="let lote of lotes">
            <div class="header-lote d-flex">
                <p>Lotes: </p>
                <p>{{lote.loteID}}</p>
            </div>
            <div class="body-lote d-flex">
                <i>{{lote.qtdRegistrosFeitos}}/{{lote.qtdRegistrosLote}}</i>
                <p>{{ lote.dataAbertura | date : 'd MMM y' }}</p>
            </div>
            <button *ngIf="loteEmAndamento(lote)" class="btn-iniciar" (click)="selectLote(lote)">
                <i class="bi bi-briefcase"></i>
                Iniciar
            </button>
            <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Usuário {{lote.nomeUsuarioVinculado}} já está atuando no lote.">
                <button class="btn-andamento" *ngIf="!loteEmAndamento(lote)">
                    <svg-icon class="icon-loader"  src="../../../assets/icon/loader-icon.svg"></svg-icon>
                    Em andamento
                </button>
            </span>
        </div>
    </div>
</section>
