<div class="container-home">
    <section class="container-blue">
        <div class="container d-flex">
            <div>
                <h2>
                    Processo de avaliação de indicação
                </h2>
                <p>
                    <i class="bi bi-chevron-right"></i>
                    Indicações
                    <i class="bi bi-chevron-right"></i>
                    Lote: {{loteId}}
                </p>
            </div>
            <button class="btn-arquivadas" type="button" class="btn" [routerLink]="['/indicacao']" (click)="desvincularLoteAoUsuario()">  
                <i class="bi bi-arrow-return-left"></i>
                Voltar
            </button>
        </div>
    </section>
    <section class="modals">
        <app-modal-informacao-lote *ngIf="carregouLote" [indicacaoCondutores]="loteSelecionado" [loteId]="loteId"></app-modal-informacao-lote>
    </section>
</div>