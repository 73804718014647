import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiCheckbox } from '../../interfaces/multi-checkbox';

@Component({
  selector: 'app-multi-checkbox',
  templateUrl: './multi-checkbox.component.html',
  styleUrls: ['./multi-checkbox.component.scss']
})
export class MultiCheckboxComponent implements OnInit {

  @Input() 
  public checkboxs: MultiCheckbox[]

  @Output()
  public checkboxSelectEmit = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public emitEventCheckbox(checkbox: MultiCheckbox) {
    this.checkboxSelectEmit.emit(checkbox.value);
  }

}
