<div class="container-home">
    <section class="container-blue">
        <div class="container d-flex">
            <div>
                <h2>
                    Avaliação de Processo
                </h2>
                <p>
                    <i class="bi bi-chevron-right"></i>
                    Processos
                    <i class="bi bi-chevron-right"></i>
                    Lote: {{loteId}}
                </p>
            </div>
            <button class="btn-arquivadas" type="button" class="btn" [routerLink]="['/recurso']" (click)="desvincularLoteAoUsuario()">
                <i class="bi bi-arrow-return-left"></i>
                Voltar
            </button>
        </div>
    </section>
    <section class="modals">
        <app-modal-informacao-recurso *ngIf="carregouLote" [recursos]="loteSelecionado" [loteId]="loteId"></app-modal-informacao-recurso>
    </section>
</div>
