<div class="container-home">
    <section class="container-blue">
        <div class="container d-flex">
            <div>
                <h2>
                    Controle de Indicações
                    <svg-icon class="icon-condutor" src="../../../assets/icon/condutor.svg"></svg-icon>
                </h2>
                <hr size="10" width="40%" align="left" class="linha-horizontal">
                <p>Controle de Indicações cadastradas e validadas.</p>
                <p>Alterações em indicações são permitidas antes da transmissão, desde que o lote já esteja fechado.</p>
                <p>
                    <i class="bi bi-chevron-right"></i>
                    Indicações
                    <i class="bi bi-chevron-right"></i>
                    Controle de Indicações
                </p>
            </div>
        </div>
    </section>
  <section class="modal-lotes containers">
    <div class="header-modal d-flex">
        <form [formGroup]="validators" class="form-recurso" (ngSubmit)="onSubmit()">
            <div class="form-group mb-3 titulo-campos-pesquisa dt-inicial">
              <label for="dataInicial" class="titulo-labels-pesquisa">Data Inicial</label>
              <input type="text"
                    id="dataInicial"
                    placeholder="Data Inicial"
                    class="form-control input-date"
                    bsDatepicker
                    formControlName="dataInicial"
                    (ngModelChange)="onSubmit()"
                    [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}">
            </div>
            <div class="form-group mb-3 titulo-campos-pesquisa">
              <label class="titulo-labels-pesquisa">Data Final</label>
              <input type="text"
                    id="dataFinal"
                    placeholder="Data Final"
                    class="form-control input-date"
                    bsDatepicker
                    formControlName="dataFinal"
                    (ngModelChange)="onSubmit()"
                    [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}">
            </div>
            <!-- <div class="form-check-change form-check form-group mb-3 p-2 titulo-campos-pesquisa">
              <label for="tipoProcesso">Tipo de documento</label>
              <select class="form-select" formControlName="tipoDocumento" id="tipoDocumento" (ngModelChange)="onSubmit()">
                  <option  *ngFor="let documento of tipoDocumento" [ngValue]="documento.value">{{documento.name}}</option>
              </select>
            </div> -->

            <div class="form-group mb-3 titulo-campos-pesquisa select-container">
              <label for="statusTransmissao" class="titulo-labels-pesquisa ">Status Transmissão</label>
              <select class="form-select input-select" formControlName="statusTransmissao" id="statusTransmissao" (ngModelChange)="onSubmit()">
                  <option value="todos">Todos</option>
                  <option value="transmitidas">Transmitidas</option>
                  <option value="transmitir">Transmitir</option>
              </select>
            </div>

            <div class="form-group mb-3 titulo-campos-pesquisa select-container">
              <label for="statusIndicacao" class="titulo-labels-pesquisa ">Status Indicação</label>
              <select class="form-select input-select" formControlName="statusIndicacao" id="statusIndicacao" (ngModelChange)="onSubmit()">
                  <option value="todos">Todos</option>
                  <option value="validas">Válidas</option>
                  <option value="invalidas">Inválidas</option>
              </select>
            </div>

            <div class="form-check-change form-check form-group mb-3 p-2 titulo-campos-pesquisa">
              <label class="titulo-labels-pesquisa">Pesquisar</label>
              <input type="text" placeholder="Digite aqui" class="form-control" formControlName="termo"  (ngModelChange)="onSubmit()">
            </div>
            <div class="form-check-change form-check form-group mb-3 p-2 Qtd titulo-campos-pesquisa">
              <label for="take" class="titulo-labels-pesquisa">Qtd</label>
                <select class="form-select quantity" formControlName="take" id="take" (ngModelChange)="onSubmit()">
                    <option  *ngFor="let take of takeFilter" [ngValue]="take">{{take}}</option>
                </select>
            </div>
        </form>
    </div>
    <div class="container-lotes d-flex">
      <table class="table">
        <thead>
          <tr>
            <th class="sortable-header" (click)="sortTable('lote')">LOTE
              <span *ngIf="currentColumn === 'lote'">
                <i class="bi" [ngClass]="getSortIcon('lote')"></i>
              </span>
          </th>
            <th class="sortable-header" (click)="sortTable('nrProtocolo')">NÚMERO DO PROTOCOLO
              <span *ngIf="currentColumn === 'nrProtocolo'">
                <i class="bi" [ngClass]="getSortIcon('nrProtocolo')"></i>
              </span>
            </th>
            <th class="sortable-header" (click)="sortTable('dataProtocolo')">DATA INDICAÇÃO
            <span *ngIf="currentColumn === 'dataProtocolo'">
                <i class="bi" [ngClass]="getSortIcon('dataProtocolo')"></i>
              </span>
            </th>
            <th class="sortable-header" (click)="sortTable('nomeIndicado')">CONDUTOR
            <span *ngIf="currentColumn === 'nomeIndicado'">
                <i class="bi" [ngClass]="getSortIcon('nomeIndicado')"></i>
              </span>
            </th>
            <th class="sortable-header" (click)="sortTable('autoInfracao')">AIT
            <span *ngIf="currentColumn === 'autoInfracao'">
                <i class="bi" [ngClass]="getSortIcon('autoInfracao')"></i>
              </span>
            </th>
            <th class="sortable-header" (click)="sortTable('placa')">PLACA
            <span *ngIf="currentColumn === 'placa'">
                <i class="bi" [ngClass]="getSortIcon('placa')"></i>
              </span>
            </th>
            <th class="sortable-header" (click)="sortTable('dataAnalise')">DATA ANÁLISE
            <span *ngIf="currentColumn === 'dataAnalise'">
                <i class="bi" [ngClass]="getSortIcon('dataAnalise')"></i>
              </span>
            </th>
            <th class="sortable-header" (click)="sortTable('motivoArquivamento')">MOTIVO ARQUIVAMENTO
            <span *ngIf="currentColumn === 'motivoArquivamento'">
                <i class="bi" [ngClass]="getSortIcon('motivoArquivamento')"></i>
              </span>
            </th>
            <th class="sortable-header" (click)="sortTable('dataTransmissao')">DATA TRANSMITIDA
            <span *ngIf="currentColumn === 'dataTransmissao'">
                <i class="bi" [ngClass]="getSortIcon('dataTransmissao')"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let indicacao of indicacoes | paginate: { itemsPerPage: take, currentPage: page }">
            <td>{{indicacao.lote}}</td>
            <td>{{indicacao.nrProtocolo}}</td>
            <td>{{indicacao.dataProtocolo | date : 'dd/MM/YYYY' }}</td>
            <td>{{indicacao.nomeIndicado.toUpperCase()}}</td>
            <td>{{indicacao.autoInfracao}}</td>
            <td>{{indicacao.placa}}</td>
            <td>{{indicacao.dataAnalise | date : 'dd/MM/yyyy' }}</td>
            <td>{{indicacao.motivoArquivamento}}</td>
            <td>{{indicacao.dataTransmissao | date : 'dd/MM/yyyy'}}</td>
            <td><button class="btn-arquivado"><i class="bi bi-eye" (click)="openDetalhamentoIndicacao(indicacao)"></i></button><td>
            <td *ngIf="!indicacao.dataTransmissao"><button class="btn-arquivado"><i class="bi bi-pencil-square" (click)="openEdicaoIndicacao(indicacao)"></i></button><td></td>
          </tr>
        </tbody>
      </table>
          <div class="footer-table">
            <pagination-controls nextLabel="Próximo" previousLabel="Anterior" (pageChange)="page = $event"></pagination-controls>
          </div>
    </div>
</section>



</div>

