import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { BaseService } from "src/app/core/services/base.service";
import { HttpService } from "src/app/core/services/http.service";

@Injectable({
    providedIn: 'root',
})
  
export class motivoArquivamentoService extends BaseService {

    constructor(oAuthService: OAuthService,
        private httpService: HttpService){
        super(oAuthService)
    }

    public async getMotivoArquivamento() : Promise<any> {
        const response = await this.httpService.get(`${this.baseUrl()}/api/motivoarquivamento`, this.getHeaderJson())
        return this.extractData(response)
    }

    public async getMotivoArquivamentoRecurso() : Promise<any> {
        const response = await this.httpService.get(`${this.baseUrl()}/api/motivoarquivamento/recurso`, this.getHeaderJson())
        return this.extractData(response)
    }
}