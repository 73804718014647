import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private storage: Storage;

    constructor() {
        this.storage = window.localStorage;
    }

    public get = (key: string, parse: boolean = false): any =>
        parse
            ? JSON.parse(this.storage.getItem(key) || '{}')
            : this.storage.getItem(key);

    public set = (key: string, value: any, stringify: boolean = false): void =>
        stringify
            ? this.storage.setItem(key, JSON.stringify(value))
            : this.storage.setItem(key, value);

    public remove = (key: string): void => this.storage.removeItem(key);
    public clear = (): void => this.storage.clear();
}